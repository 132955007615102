import { UserRecipeId } from "@eatbetter/recipes-shared";
import { navTree, ReportRecipeIssueScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useMemo } from "react";
import { useSurveyOptions } from "../components/SurveyOptions";
import { HeaderProps } from "../components/ScreenHeaders";
import { View } from "react-native";
import { TBody, Text, TextProps, TSecondary } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { ReportSocialMediaRecipeMissingIssue } from "@eatbetter/composite-shared";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { bottomThrow, parseInteger, switchReturn } from "@eatbetter/common-shared";
import { useResponsiveDimensions } from "../components/Responsive";
import { ScrollViewWithSubmit } from "./ScrollViewWithSubmit";
import React from "react";
import { BottomSheetContent, useBottomSheet } from "./BottomSheetScreen";
import { globalStyleColors } from "../components/GlobalStyles";
import { Haptics } from "../components/Haptics";
import { useDispatch } from "../lib/redux/Redux";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportFeatureRequested } from "../lib/analytics/AnalyticsEvents";
import { useToast } from "../components/Toast";

const strings = {
  screenTitle: "Report a Recipe Issue",
  headline: "If there is a recipe here and we missed it, we'll look into it.",
  surveylabel: "Where is the recipe located?",
  options: [
    "Written recipe in the caption",
    "Link to recipe in the caption",
    "Link in bio",
    "In the comments section",
    "In video: audio",
    "In video: subtitles / captions",
  ] as const,
  next: "Next",
  unsupportedBottomSheet: {
    title: {
      commentsSection: "Recipe in Comments",
      linkInBio: "Recipe Link in Bio",
      videoAudio: "Recipe in Video Audio",
      videoCaptions: "Recipe in Video Subtitles / Captions",
    } satisfies Record<SocialMediaNonIssueType, string>,
    body: {
      commentsSection: [
        "Deglaze doesn't look for recipes in the comments section. Tip: you can take a screenshot of the recipe and ",
        "import it as a photo",
        ".",
      ],
      linkInBio: [
        "Deglaze doesn't follow links in bio. To add the recipe, open the link in bio and ",
        "share to Deglaze",
        ".",
      ],
      videoAudio: [
        "Deglaze doesn't import recipes from audio. If this is a feature you'd like to see, ",
        "send us a feature request",
        ".",
      ],
      videoCaptions: [
        "Deglaze doesn't import recipes from video substitles / captions. If this is a feature you'd like to see, ",
        "send us a feature request",
        ".",
      ],
    } satisfies Record<SocialMediaNonIssueType, [string, string, string]>,
    done: "Got it",
  },
  featureRequestToast: "Your feature request was submitted successfully",
};

type SocialMediaNonIssueType = "linkInBio" | "videoAudio" | "videoCaptions" | "commentsSection";

const surveyData: Array<{
  key: Exclude<ReportSocialMediaRecipeMissingIssue["issue"], "other"> | SocialMediaNonIssueType;
  displayText: string;
}> = [
  {
    key: "writtenRecipeInCaption",
    displayText: strings.options[0],
  },
  {
    key: "recipeLinkInCaption",
    displayText: strings.options[1],
  },
  {
    key: "linkInBio",
    displayText: strings.options[2],
  },
  {
    key: "commentsSection",
    displayText: strings.options[3],
  },
  {
    key: "videoAudio",
    displayText: strings.options[4],
  },
  {
    key: "videoCaptions",
    displayText: strings.options[5],
  },
];

export const ReportSocialMediaRecipeMissingIssueScreen = withScreenContainer<ReportRecipeIssueScreenProps>(
  "ReportSocialMediaRecipeMissingIssueScreen",
  props => {
    const dispatch = useDispatch();
    const screen = useScreen();

    const surveyOptions = useSurveyOptions("singleSelect", surveyData);
    const selectedOption = surveyOptions.selected[0];
    const renderSurveyOptions = surveyOptions.renderSurveyOptions;

    const toast = useToast();
    const navDelay = 300;

    const onPressUnsupportedOptionSuggestedLink = useCallback(
      (type: SocialMediaNonIssueType) => {
        switch (type) {
          case "commentsSection": {
            Haptics.feedback("itemStatusChanged");
            setTimeout(() => screen.nav.goTo("replace", navTree.get.screens.recipeAddFromPhotos), navDelay);
            return;
          }
          case "linkInBio": {
            Haptics.feedback("itemStatusChanged");
            setTimeout(() => screen.nav.switchTab("profileTab", navTree.get.screens.howToAddBrowserRecipe), navDelay);
            return;
          }
          case "videoAudio": {
            dispatch(analyticsEvent(reportFeatureRequested({ type: "saveRecipeFromVideoAudio" })));
            Haptics.feedback("operationSucceeded");
            setTimeout(() => screen.nav.pop(props.popCount));
            toast.show({ type: "infoToast", message: strings.featureRequestToast });
            return;
          }
          case "videoCaptions": {
            dispatch(analyticsEvent(reportFeatureRequested({ type: "saveRecipeFromVideoCaptions" })));
            Haptics.feedback("operationSucceeded");
            setTimeout(() => screen.nav.pop(props.popCount));
            toast.show({ type: "infoToast", message: strings.featureRequestToast });
            return;
          }
          default:
            bottomThrow(type);
        }
      },
      [screen.nav.goTo, screen.nav.switchTab, props.popCount, toast]
    );

    const onNext = useCallback(() => {
      if (!selectedOption) {
        displayUnexpectedErrorAndLog("onNext called but selectedOption is falsy", { selectedOption, surveyData });
        return;
      }

      Haptics.feedback("itemStatusChanged");

      switch (selectedOption.key) {
        case "writtenRecipeInCaption":
        case "recipeLinkInCaption": {
          const issueType = switchReturn(selectedOption.key, {
            recipeLinkInCaption: "linkInCaption" as const,
            writtenRecipeInCaption: "recipeInCaption" as const,
          });
          screen.nav.goTo("push", navTree.get.screens.reportSocialMediaCaptionIssue, {
            recipeId: props.recipeId,
            issueType,
            popCount: props.popCount + 1,
          });
          break;
        }
        case "linkInBio":
        case "commentsSection":
        case "videoAudio":
        case "videoCaptions": {
          screen.nav.modal(navTree.get.screens.bottomSheet, {
            content: (
              <UnsupportedOptionsSheet
                type={selectedOption.key}
                onPressSuggestedLink={onPressUnsupportedOptionSuggestedLink}
              />
            ),
            backgroundColor: "white",
          });
          break;
        }
        default:
          bottomThrow(selectedOption.key);
      }
    }, [selectedOption, props.recipeId, props.popCount, onPressUnsupportedOptionSuggestedLink]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
      };
    }, []);

    const { defaultFontScale } = useResponsiveDimensions();

    const commonTextProps: Partial<TextProps> = {
      align: "center",
      enableFontScaling: "upOnly",
      scale: defaultFontScale,
    };

    return (
      <ScreenView header={header} scrollView={false} paddingVertical={"none"}>
        <ScrollViewWithSubmit onPressSubmit={onNext} submitText={strings.next} submitDisabled={!selectedOption}>
          <Spacer vertical={1} />
          <View style={{ alignSelf: "center" }}>
            <TBody {...commonTextProps} fontWeight="medium">
              {strings.surveylabel}
            </TBody>
            <View style={{ maxWidth: "90%" }}>
              <TSecondary {...commonTextProps} opacity="dark">
                {strings.headline}
              </TSecondary>
            </View>
          </View>
          <Spacer vertical={2} />
          {renderSurveyOptions()}
          <Spacer vertical={2} />
        </ScrollViewWithSubmit>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: s => s.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => parseInteger(s),
    },
  }
);

interface UnsupportedOptionsSheetProps {
  type: SocialMediaNonIssueType;
  onPressSuggestedLink: (type: SocialMediaNonIssueType) => void;
}

const UnsupportedOptionsSheet = React.memo((props: UnsupportedOptionsSheetProps) => {
  const bottomSheet = useBottomSheet();

  const onPressDone = useCallback(() => {
    Haptics.feedback("itemStatusChanged");
    bottomSheet?.closeSheetAndGoBack();
  }, [bottomSheet]);

  const onPressLink = useCallback(() => {
    bottomSheet?.closeSheetAndGoBack();
    props.onPressSuggestedLink(props.type);
  }, [bottomSheet, props.onPressSuggestedLink]);

  const title = strings.unsupportedBottomSheet.title[props.type];

  const [first, link, period] = strings.unsupportedBottomSheet.body[props.type];

  return (
    <BottomSheetContent
      title={title}
      actionButtonText={strings.unsupportedBottomSheet.done}
      onPressActionButton={onPressDone}
    >
      <Text fontSize="body" responsive>
        <Text fontSize="body" responsive>
          {first}
        </Text>
        <Text
          fontSize="body"
          onPress={onPressLink}
          suppressHighlighting
          color={globalStyleColors.colorTextLink}
          fontWeight="medium"
          responsive
        >
          {link}
        </Text>
        <Text fontSize="body" responsive>
          {period}
        </Text>
      </Text>
    </BottomSheetContent>
  );
});
