import { AdminScreenView } from "../components/AdminScreenView";
import { AdminSocialFeed } from "../components/AdminSocialFeed";
import { useState } from "react";
import { SegmentedControl, Spacer } from "@eatbetter/ui-shared";
import { AdminRecipeFeed } from "../components/AdminRecipeFeed.tsx";

export const AdminHomeScreenNav = {
  getPath: () => "/",
};

export const AdminHomeScreen = () => {
  const [feed, setFeed] = useState<"all" | "curated" | "newRecipePosts" | "autoFiltered" | "recipes:UserPhotos">(
    "autoFiltered"
  );

  const segments = [
    {
      label: "Auto-Filtered",
      onPress: () => setFeed("autoFiltered"),
      isSelected: feed === "autoFiltered",
    },
    {
      label: "All",
      onPress: () => setFeed("all"),
      isSelected: feed === "all",
    },
    {
      label: "New Recipes",
      onPress: () => setFeed("newRecipePosts"),
      isSelected: feed === "newRecipePosts",
    },
    {
      label: "Curated",
      onPress: () => setFeed("curated"),
      isSelected: feed === "curated",
    },
    {
      label: "Photo Recipes",
      onPress: () => setFeed("recipes:UserPhotos"),
      isSelected: feed === "recipes:UserPhotos",
    },
  ];
  return (
    <AdminScreenView>
      <SegmentedControl segments={segments} height="small" width={550} />
      <Spacer vertical={1} />
      {feed !== "recipes:UserPhotos" && <AdminSocialFeed feed={feed} />}
      {feed === "recipes:UserPhotos" && <AdminRecipeFeed feed="userPhotoRecipes" />}
    </AdminScreenView>
  );
};
