import {
  CameraType,
  ImagePickerAsset,
  launchCameraAsync,
  launchImageLibraryAsync,
  MediaTypeOptions,
  PermissionStatus,
  requestCameraPermissionsAsync,
  UIImagePickerPreferredAssetRepresentationMode,
} from "expo-image-picker";
import { log } from "../../Log";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import { SuccessOrFailure } from "@eatbetter/common-shared";
import { AppAddPhotoArgs } from "../Types";
import { getMediaType } from "./UploadPhoto";
import { Alert } from "../../components/Alert/Alert";
import { Linking } from "react-native";

export type SelectPhotoFailureReason = "canceled" | "error";
export type TakePhotoFailureReason = SelectPhotoFailureReason | "accessDenied";

export const selectPhotoFromLibrary = async (args?: {
  onBeginProcessing?: () => void;
}): Promise<SuccessOrFailure<AppAddPhotoArgs, { reason: SelectPhotoFailureReason }>> => {
  const res = await selectPhotosFromLibrary({ maxCount: 1, onBeginProcessing: args?.onBeginProcessing });

  if (res.success) {
    if (res.result.length !== 1) {
      log.error(`selectPhotosFromLibrary returned ${res.result.length} results when exactly 1 was expected`);
      return { success: false, result: { reason: "error" } };
    }

    return { success: true, result: res.result[0]! };
  }

  // failure case
  return res;
};

export const selectPhotosFromLibrary = async (args: {
  maxCount: number;
  ordered?: boolean;
  onBeginProcessing?: (count: number) => void;
}): Promise<SuccessOrFailure<AppAddPhotoArgs[], { reason: SelectPhotoFailureReason }>> => {
  try {
    const allowsMultipleSelection = args.maxCount > 1;
    const selectionLimit = allowsMultipleSelection ? args.maxCount : undefined;
    // use the native image format here and it will get converted to jpg when we process
    const res = await launchImageLibraryAsync({
      quality: 1,
      allowsMultipleSelection,
      selectionLimit,
      orderedSelection: args.ordered,
      mediaTypes: MediaTypeOptions.Images,
      preferredAssetRepresentationMode: UIImagePickerPreferredAssetRepresentationMode.Current,
    });

    if (res.canceled) {
      return { success: false, result: { reason: "canceled" } };
    }

    args.onBeginProcessing?.(res.assets.length);
    const result = await Promise.all(res.assets.map(processPhoto));

    return {
      success: true,
      result,
    };
  } catch (err) {
    log.errorCaught("Error selecting photos from library", err);
    return { success: false, result: { reason: "error" } };
  }
};

export const selectPhotoFromCamera = async (): Promise<
  SuccessOrFailure<AppAddPhotoArgs, { reason: TakePhotoFailureReason }>
> => {
  try {
    const perm = await requestCameraPermissionsAsync();
    if (perm.status === PermissionStatus.DENIED) {
      return { success: false, result: { reason: "accessDenied" } };
    }

    // use the native file format here and it will get converted to jpg when we process the image
    const resp = await launchCameraAsync({
      cameraType: CameraType.back,
      mediaTypes: MediaTypeOptions.Images,
      preferredAssetRepresentationMode: UIImagePickerPreferredAssetRepresentationMode.Current,
      quality: 1,
    });

    if (resp.canceled) {
      return { success: false, result: { reason: "canceled" } };
    }

    if (resp.assets.length !== 1) {
      log.error(`Expecting exactly 1 asset from launchCameraAsync. Got ${resp.assets.length}`);
      return { success: false, result: { reason: "error" } };
    }

    const a = resp.assets[0]!;

    const result = await processPhoto(a);
    return { success: true, result };
  } catch (err) {
    log.errorCaught("Error in launchCameraAsync", err);
    return { success: false, result: { reason: "error" } };
  }
};

export function showCameraPermissionAlertAndNavToSettings() {
  Alert.alert("We need access to your camera", "Tap okay to go to Settings to enable camera access", [
    { type: "cancel", onPress: () => {} },
    { type: "save", text: "Okay", onPress: () => Linking.openSettings() },
  ]);
}

async function processPhoto(a: ImagePickerAsset): Promise<AppAddPhotoArgs> {
  const targetWidth = 1290;

  // Resize/compress/crop photo
  // Instagram's image dimensions/aspect ratios: https://help.instagram.com/1631821640426723
  const res = await manipulateAsync(
    a.uri,
    [
      {
        resize: {
          width: a.width > targetWidth ? targetWidth : a.width,
          // if height not specified, aspect ratio is preserved
        },

        // determine desired aspect ratio range and c rop
      },
    ],
    {
      // The file size is much smaller with even very high quality compression (0.9)
      // in the example I tried, it went from 2.7MB -> < 1MB
      // This is a lot if the user is not on a fast connection
      // but we'll stick with uncompressed for the moment
      compress: 1.0, // Range is 0.0 to 1.0 (0.0 is maximum compression, 1.0 is no compression)
      format: SaveFormat.JPEG,
    }
  );

  return {
    uri: res.uri,
    mediaType: getMediaType(res.uri),
    dimensions: { width: res.width, height: res.height },
  };
}
