import React, { useCallback } from "react";
import { useBottomSheet } from "../screens/BottomSheetScreen.tsx";
import { bottomThrow } from "@eatbetter/common-shared";
import { OptionsMenu, OptionsMenuItem } from "./OptionsMenu.tsx";
import { Pressable } from "./Pressable.tsx";
import { ContainerFlexCentered } from "./Containers.tsx";
import { FlexedSpinner, Spinner } from "./Spinner.tsx";
import { IconCamera, IconCircleCheck, IconEx } from "./Icons.tsx";
import { globalStyleColors, globalStyleConstants, globalStyles } from "./GlobalStyles.ts";
import { Spacer } from "./Spacer.tsx";
import { TSecondary } from "./Typography.tsx";
import { Image, StyleSheet, View } from "react-native";
import { AppAddPhotoArgs } from "../lib/Types.ts";
import { useScreen } from "../navigation/ScreenContainer.tsx";
import { navTree } from "../navigation/NavTree.ts";
import { ShimmerEffect } from "./AttentionGrabbers.tsx";

const strings = {
  addPhoto: "Add Photo",
  addPhotos: "Add Photos",
  takePhoto: "Take a Photo",
  selectPhoto: "Select Photo from Library",
  selectPhotos: "Select Photos from Library",
};

export const AddPhotoOptionsSheet = React.memo(
  (props: { takePhoto: () => void; selectFromLibrary: () => void; mode: "single" | "multi" }) => {
    const bottomSheet = useBottomSheet();

    const doAction = useCallback(
      (type: "camera" | "library") => {
        bottomSheet?.closeSheetAndGoBack();
        setTimeout(() => {
          switch (type) {
            case "camera": {
              props.takePhoto();
              break;
            }
            case "library": {
              props.selectFromLibrary();
              break;
            }
            default:
              bottomThrow(type);
          }
        }, 200);
      },
      [bottomSheet]
    );

    const onPressTakePhoto = useCallback(() => {
      doAction("camera");
    }, [doAction]);

    const onPressSelectFromLibrary = useCallback(() => {
      doAction("library");
    }, [doAction]);

    return (
      <OptionsMenu>
        <OptionsMenuItem icon="camera" text={strings.takePhoto} onPress={onPressTakePhoto} isFirst />
        <OptionsMenuItem
          icon="image"
          text={props.mode === "multi" ? strings.selectPhotos : strings.selectPhoto}
          onPress={onPressSelectFromLibrary}
        />
      </OptionsMenu>
    );
  }
);

export const AddPhotosButton = React.memo(
  (props: { onPress: () => void; waiting: boolean; disabled: boolean; mode: "single" | "multi" }) => {
    return (
      <Pressable
        style={[styles.tile, styles.addPhotoButton]}
        onPress={props.onPress}
        disabled={props.disabled || props.waiting}
      >
        <ContainerFlexCentered>
          {!!props.waiting && <Spinner size={30} />}
          {!props.waiting && <IconCamera opacity="opaque" size={30} color={globalStyleColors.colorAccentCool} />}
          <Spacer vertical={0.5} />
          <TSecondary fontWeight="medium">{props.mode === "multi" ? strings.addPhotos : strings.addPhoto}</TSecondary>
        </ContainerFlexCentered>
      </Pressable>
    );
  }
);

export const PhotoPlaceholder = React.memo((props: { endOfRow: boolean }) => {
  return (
    <View style={[styles.tile, styles.processing, props.endOfRow ? {} : styles.tileMarginRight]}>
      <FlexedSpinner />
    </View>
  );
});

export const SelectedPhoto = React.memo(
  (props: {
    endOfRow: boolean;
    photo: AppAddPhotoArgs;
    uploadPercentComplete?: number;
    onDelete: (photo: AppAddPhotoArgs) => void;
  }) => {
    const screen = useScreen();

    const onPressPhoto = useCallback(() => {
      screen.nav.modal(navTree.get.screens.viewPhoto, { photo: props.photo.uri });
    }, [screen.nav.modal, props.photo.uri]);

    return (
      <Pressable onPress={onPressPhoto} style={[styles.tile, props.endOfRow ? {} : styles.tileMarginRight]}>
        <Image
          style={{
            width: "100%",
            height: "100%",
            borderRadius: globalStyleConstants.defaultBorderRadius,
            overflow: "hidden",
          }}
          source={{ uri: props.photo.uri }}
          resizeMode="cover"
        />
        {!!props.uploadPercentComplete && (
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: globalStyleColors.rgba("black", "light"),
                justifyContent: "center",
                alignItems: "center",
                borderRadius: globalStyleConstants.defaultBorderRadius,
              },
            ]}
          >
            {props.uploadPercentComplete < 100 && (
              <>
                <PhotoUploadProgressBar progress={props.uploadPercentComplete} />
              </>
            )}
            {props.uploadPercentComplete === 100 && <IconCircleCheck opacity="opaque" color={"white"} />}
          </View>
        )}
        {!props.uploadPercentComplete && <UnselectPhotoButton onPress={() => props.onDelete(props.photo)} />}
      </Pressable>
    );
  }
);

const PhotoUploadProgressBar = React.memo((props: { progress: number }) => {
  return (
    <View style={styles.progressBarWrap}>
      <View style={[styles.progressBar, { width: `${props.progress}%` }]}>
        <ShimmerEffect backgroundColor="colorAccentCool" />
      </View>
    </View>
  );
});

const UnselectPhotoButton = React.memo((props: { onPress: () => void }) => {
  const offset = 0.5 * globalStyleConstants.unitSize;

  return (
    <View style={{ position: "absolute", top: -offset, right: -offset }}>
      <Pressable hitSlop={globalStyleConstants.unitSize} style={styles.unselectPhotoButton} onPress={props.onPress}>
        <IconEx opacity="opaque" color={globalStyleColors.black} size={16} strokeWidth={2} />
      </Pressable>
    </View>
  );
});

const styles = StyleSheet.create({
  addPhotoButton: {
    borderWidth: 1,
    borderColor: globalStyleColors.colorAccentCool,
    borderStyle: "dashed",
  },
  processing: {
    borderWidth: 1,
    borderColor: globalStyleColors.colorAccentCool,
    borderStyle: "dashed",
  },
  progressBar: {
    flex: 1,
    borderRadius: Number.MAX_SAFE_INTEGER,
    overflow: "hidden",
  },
  progressBarWrap: {
    width: "50%",
    height: 11,
    padding: 0.5,
    borderRadius: Number.MAX_SAFE_INTEGER,
    backgroundColor: "white",
    overflow: "hidden",
  },
  tile: {
    width: "30%",
    aspectRatio: 1,
    borderRadius: globalStyleConstants.defaultBorderRadius,
    marginBottom: "5%",
  },
  tileMarginRight: {
    marginRight: "5%",
  },
  unselectPhotoButton: {
    borderRadius: Number.MAX_SAFE_INTEGER,
    padding: 4,
    backgroundColor: globalStyleColors.white,
    ...globalStyles.shadow,
  },
});
