import { UserRecipeId } from "@eatbetter/recipes-shared";
import { ReportRecipeIssueScreenProps, navTree } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { reportIssue } from "../lib/system/SystemThunks";
import { useSurveyOptions } from "../components/SurveyOptions";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScrollView } from "react-native";
import { BottomActionBar } from "../components/BottomActionBar";
import { useRecipeSourceType, useRecipeTitle } from "../lib/recipes/RecipesSelectors";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { TBody } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { ReportRecipeIssue } from "@eatbetter/composite-shared";
import { bottomLog, bottomThrow, parseInteger, switchReturn } from "@eatbetter/common-shared";

const strings = {
  screenTitle: "Report a Recipe Issue",
  headline: "If this recipe didn't import as expected, you can report it and we'll try to fix it.",
  options: ["Recipe is missing parts", "Recipe parts are incorrect", "Book is incorrect", "Other"] as const,
  next: "Next",
  otherInputLabel: "Please describe the issue",
  otherTextInputPlaceholder: "Describe the issue with this recipe...",
  missingPartsLabel: "What parts are missing?",
  incorrectPartsLabel: "What parts are incorrect?",
};

const surveyData: Array<{ key: Exclude<ReportRecipeIssue["issue"], "missingRecipe">; displayText: string }> = [
  {
    key: "missingParts",
    displayText: strings.options[0],
  },
  {
    key: "incorrectParts",
    displayText: strings.options[1],
  },
  {
    key: "incorrectBook",
    displayText: strings.options[2],
  },
  {
    key: "other",
    displayText: strings.options[3],
  },
];

export const ReportRecipeIssueScreen = withScreenContainer<ReportRecipeIssueScreenProps>(
  "ReportRecipeIssueScreen",
  props => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const recipeTitle = useRecipeTitle(props.recipeId);

    const recipeSourceType = useRecipeSourceType(props.recipeId);

    // Filter data appropriately - only show the incorrectBook option if we know it's a userPhoto recipe source type
    const filteredSurveyData = surveyData.filter(i => {
      if (!recipeSourceType) {
        return i.key !== "incorrectBook";
      }

      switch (recipeSourceType) {
        case "userPhoto": {
          return true;
        }
        case "book":
        case "url":
        case "user": {
          return i.key !== "incorrectBook";
        }
        default: {
          bottomLog(recipeSourceType, "ReportRecipeIssueScreen.filteredSurveryData");
          return i.key !== "incorrectBook";
        }
      }
    });

    const surveyOptions = useSurveyOptions("singleSelect", filteredSurveyData);
    const selectedOption = surveyOptions.selected[0];
    const renderSurveyOptions = surveyOptions.renderSurveyOptions;

    const [waiting, setWaiting] = useState(false);

    const submit = useCallback(
      async (otherText?: string) => {
        try {
          if (!selectedOption) {
            throw new Error("ReportRecipeIssueScreen: submit() called but selectedOption is falsy");
          }

          await dispatch(
            reportIssue(
              { type: "recipeIssue", recipeId: props.recipeId, issue: selectedOption.key, otherText },
              setWaiting
            )
          );
        } catch (err) {
          displayUnexpectedErrorAndLog("ReportRecipeIssueScreen: error caught in submit()", err, {
            otherText,
            recipeId: props.recipeId,
          });

          if (otherText) {
            // Throw so we can catch and handle appropriately in the "other feedback" form
            throw err;
          }

          // Don't nav back on error
          return;
        }
        Haptics.feedback("operationSucceeded");
        screen.nav.goBack();
      },
      [dispatch, selectedOption, props.recipeId, setWaiting, screen.nav.goBack]
    );

    const onSubmitWithTextFeedback = useCallback(
      async (otherText: string) => {
        await submit(otherText);
      },
      [submit]
    );

    const onSubmit = useCallback(async () => {
      if (!selectedOption) {
        displayUnexpectedErrorAndLog("onSubmit called but no survey option is selected", {}, { selectedOption });
        return;
      }

      switch (selectedOption.key) {
        case "incorrectBook": {
          screen.nav.goTo("replace", navTree.get.screens.attributionQuestions, {
            popCount: 1,
            recipeId: props.recipeId,
          });
          break;
        }
        case "incorrectParts":
        case "missingParts":
        case "other": {
          const label = switchReturn(selectedOption.key, {
            incorrectParts: strings.incorrectPartsLabel,
            missingParts: strings.missingPartsLabel,
            other: strings.otherInputLabel,
          });

          screen.nav.modal(navTree.get.screens.surveyOtherOptionForm, {
            screenTitle: strings.screenTitle,
            label,
            textInputPlaceholder: strings.otherTextInputPlaceholder,
            onSubmit: onSubmitWithTextFeedback,
          });
          break;
        }
        default:
          bottomThrow(selectedOption.key);
      }
    }, [screen.nav.goTo, selectedOption, onSubmitWithTextFeedback]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
      };
    }, []);

    return (
      <ScreenView header={header} scrollView={false}>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <TBody align="center" fontWeight="medium" numberOfLines={1}>
            {recipeTitle}
          </TBody>
          <Spacer vertical={1} />
          <TBody align="center" numberOfLines={2} adjustsFontSizeToFit>
            {strings.headline}
          </TBody>
          <Spacer vertical={2} />
          {renderSurveyOptions()}
          <Spacer vertical={2} />
        </ScrollView>
        <BottomActionBar
          primaryAction={{
            actionText: strings.next,
            onPressAction: onSubmit,
            disabled: !selectedOption,
            waiting,
          }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: s => s.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => parseInteger(s),
    },
  }
);
