import React, { useCallback, useEffect, useState } from "react";
import { Photo, PhotoProps } from "./Photo";
import { PhotoRef } from "@eatbetter/photos-shared";
import { StyleSheet, View } from "react-native";
import { BlurOverlay } from "./BlurOverlay/BlurOverlay";
import { RecipeInfo } from "@eatbetter/recipes-shared";
import { TabView, TabViewRoute } from "./TabView";
import { bottomLog } from "@eatbetter/common-shared";
import { globalStyleConstants } from "./GlobalStyles";
import { TabViewProgress } from "./TabViewProgress";
import { smallScreenBreakpoint, useResponsiveDimensions } from "./Responsive";

interface RecipePhotoProps extends Pick<PhotoProps, "sourceSize" | "onPress"> {
  recipe: RecipeInfo;
}

export function recipeHasUsablePhoto(recipe: RecipeInfo): boolean {
  return !!getPhoto(recipe).photo;
}

export const RecipePhoto = React.memo((props: RecipePhotoProps) => {
  const { recipe, ...photoProps } = props;
  const { photo, isBookPhoto } = getPhoto(recipe);

  if (!photo) {
    // Return the default photo placeholder
    return <Photo style="flexed" sourceSize={props.sourceSize} />;
  }

  if (!isBookPhoto) {
    return <Photo {...photoProps} source={photo} style="flexed" resizeMode="cover" />;
  }

  // blur it
  return <BookCoverPhoto {...photoProps} source={photo} />;
});

interface RecipeDetailPhotosProps extends Pick<PhotoProps, "onPress"> {
  recipe: RecipeInfo;
  isRestrictedBookPhoto?: boolean;
}

/**
 * Display the recipe photo(s) on the recipe detail screen
 */
export const RecipeDetailPhotos = React.memo((props: RecipeDetailPhotosProps) => {
  const { recipe, ...photoProps } = props;
  const [index, setIndex] = useState(0);

  const photos = getRecipeDetailPhotos(props.recipe, !!props.isRestrictedBookPhoto);

  const photosByKey = Object.fromEntries(
    photos.flatMap<[string, { photo: PhotoRef; isBookPhoto: boolean }]>(i => {
      if (!i.photo) {
        return [];
      }

      switch (i.photo.type) {
        case "internal": {
          return [[i.photo.id, { photo: i.photo, isBookPhoto: i.isBookPhoto }]];
        }
        case "external": {
          return [[i.photo.url, { photo: i.photo, isBookPhoto: i.isBookPhoto }]];
        }
        default: {
          bottomLog(i.photo, "RecipeDetailPhotos.photos");
          return [];
        }
      }
    })
  );

  const routes: TabViewRoute[] = Object.entries(photosByKey).map(([key]) => ({ key, title: key }));

  const renderRoute = useCallback(
    (routeProps: { route: TabViewRoute }) => {
      const photo = photosByKey[routeProps.route.key];

      if (!photo) {
        return null;
      }

      if (photo.isBookPhoto) {
        return <BookCoverPhoto source={photo.photo} sourceSize="w1290" onPress={"expand"} />;
      }

      return <Photo {...photoProps} style="flexed" source={photo.photo} sourceSize="w1290" />;
    },
    [routes, photosByKey, props.isRestrictedBookPhoto]
  );

  // If photo count changes (i.e. user adds a photo), go to the beginning so that they see the photo they just
  // when they nav back from edit recipe.
  useEffect(() => {
    setIndex(0);
  }, [routes.length]);

  const dimensions = useResponsiveDimensions();
  const photoHeight =
    dimensions.width > smallScreenBreakpoint ? styles.recipePhotoHeightLarge : styles.recipePhotoHeight;

  if (photos.length === 0) {
    return null;
  }

  return (
    <View style={[styles.recipePhoto, photoHeight]}>
      <TabView
        routes={routes}
        index={index}
        onIndexChange={setIndex}
        tabBar={"none"}
        renderRoute={renderRoute}
        swipeEnabled={routes.length > 1}
        initialWidthHint={dimensions.width - 2 * globalStyleConstants.minPadding}
      />
      {routes.length > 1 && (
        <View style={{ position: "absolute", alignSelf: "center", bottom: globalStyleConstants.unitSize }}>
          <TabViewProgress count={routes.length} currentIndex={index} style="overlay" />
        </View>
      )}
    </View>
  );
});

export const BookCoverPhoto = React.memo((props: Pick<PhotoProps, "source" | "sourceSize" | "onPress">) => {
  return (
    <View style={{ flex: 1 }}>
      <View style={StyleSheet.absoluteFill}>
        <Photo {...props} source={props.source} style="flexed" resizeMode="stretch" sourceSize={props.sourceSize} />
        <BlurOverlay style="photoBlur" noLayoutAnimation />
      </View>
      <View style={[{ flex: 1, padding: "5%" }, !props.source ? { aspectRatio: 0.8, alignSelf: "center" } : {}]}>
        {/* We suppress the loading placeholder here because this photo doesn't occupy the full area of the parent container and
        you can see the padding when it loads, which doesn't look great. It's enough that the blurred backdrop is loading, and
        by the time that ones done, this one will be as well. */}
        <Photo
          {...props}
          source={props.source}
          style="flexed"
          resizeMode="contain"
          noBorderRadius
          showLoadingPlaceholder={false}
        />
      </View>
    </View>
  );
});

/**
 * Get the primary recipe photo to be used everywhere except recipe detail screen.
 * The logic is slightly different for the detail screen, which should use getPhotos below.
 * Namely, on the detail screen we don't show the book photo cover if we have source photos, since the source photos are useful
 * and the book cover is already rendered in the book section.
 * Anywhere else (recipe list, posts, etc.), we prefer the book photo over the source photo since the source photo isn't going to look
 * great and will be pretty much useless in thumbnail size.
 * @param recipe
 */
function getPhoto(recipe: RecipeInfo): { photo?: PhotoRef; isBookPhoto: boolean } {
  const firstSourcePhoto = recipe.source.type === "userPhoto" ? recipe.source.photos[0] : undefined;
  const userEnteredBookPhoto =
    recipe.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.photo : undefined;
  const bookPhoto = recipe.book?.photo ?? userEnteredBookPhoto;
  const photo = recipe.photo ?? bookPhoto ?? recipe.publisher?.photo ?? firstSourcePhoto;
  return { photo, isBookPhoto: !!recipe.book?.photo && photo === recipe.book.photo };
}

function getRecipeDetailPhotos(
  recipe: RecipeInfo,
  isRestrictedBookRecipe?: boolean
): Array<{ photo?: PhotoRef; isBookPhoto: boolean }> {
  if (recipe.source.type === "userPhoto") {
    if (isRestrictedBookRecipe) {
      if (!recipe.book && recipe.userEnteredAttribution?.type === "book") {
        return [{ photo: recipe.userEnteredAttribution.photo, isBookPhoto: true }];
      }

      return [{ photo: recipe.book?.photo, isBookPhoto: true }];
    }

    const userEnteredBookPhoto =
      recipe.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.photo : undefined;
    const bookPhoto = recipe.book?.photo ?? userEnteredBookPhoto;

    return [
      { photo: recipe.photo, isBookPhoto: false },
      { photo: bookPhoto, isBookPhoto: true },
      ...recipe.source.photos.map(photo => ({ photo, isBookPhoto: false })),
    ];
  } else {
    return [getPhoto(recipe)].filter(i => !!i.photo);
  }
}

const styles = StyleSheet.create({
  recipePhoto: {
    width: "100%",
    borderRadius: 20,
    overflow: "hidden",
  },
  recipePhotoHeight: {
    height: 256,
  },
  recipePhotoHeightLarge: {
    height: 464,
  },
});
