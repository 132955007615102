import { useScreen, withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { EditSimpleAttributionScreenProps } from "../navigation/NavTree.ts";
import { EditUserRecipeAttributionArgs, UserRecipeId } from "@eatbetter/recipes-shared";
import { ScreenView } from "../components/ScreenView.tsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { globalStyleColors, globalStyleConstants, globalStyles } from "../components/GlobalStyles.ts";
import { ScrollView, View } from "react-native";
import { TextInput, TextInputHandle } from "../components/TextInput.tsx";
import { Spacer } from "../components/Spacer.tsx";
import { useDispatch } from "../lib/redux/Redux.ts";
import { useExistingSimpleAttribution, useLibraryRecipe } from "../lib/recipes/RecipesSelectors.ts";
import { editRecipeAttribution } from "../lib/recipes/RecipesThunks.ts";
import { displayUnexpectedErrorAndLog } from "../lib/Errors.ts";
import { InputAccessoryView } from "../components/InputAccessoryView.tsx";
import { BottomActionBar } from "../components/BottomActionBar.tsx";
import { TBody } from "../components/Typography.tsx";
import { Pressable } from "../components/Pressable.tsx";

const strings = {
  title: "Edit Recipe By",
  placeholder: "Grandma, Martha Stewart, etc",
  save: "Save",
};

export const EditSimpleAttributionScreen = withScreenContainer(
  "EditSimpleAttributionScreen",
  (props: EditSimpleAttributionScreenProps) => {
    const screen = useScreen();
    const recipe = useLibraryRecipe(props.recipeId);
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);
    const [inputText, setInputText] = useState(
      recipe?.userEnteredAttribution?.type === "simple" ? recipe.userEnteredAttribution.name : ""
    );
    const textInputRef = useRef<TextInputHandle>(null);

    useEffect(() => {
      if (!recipe) {
        screen.nav.pop(props.popCount);
      }
    }, [!!recipe, screen.nav]);

    useEffect(() => {
      setTimeout(() => textInputRef.current?.focus(), 650);
    }, []);

    const onSave = useCallback(async () => {
      if (!recipe) {
        return;
      }

      const trimmed = inputText.trim();

      const args: EditUserRecipeAttributionArgs = {
        recipeId: recipe.id,
        version: recipe.version,
        type: "userEntered",
        attribution: trimmed ? { type: "simple", name: trimmed } : null,
      };

      try {
        await dispatch(editRecipeAttribution(args, "Edit Simple Source Screen", setWaiting));
        screen.nav.pop(props.popCount);
      } catch (err) {
        displayUnexpectedErrorAndLog(
          "Unexpected error dispatching editRecipeAttribution on EditSimpleAttributionScreen",
          err,
          { args }
        );
      }
    }, [recipe, dispatch, inputText]);

    const onSelect = useCallback(
      (tapped: string) => {
        setInputText(tapped);
      },
      [setInputText]
    );

    const existing = useExistingSimpleAttribution();

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        backgroundColor: globalStyleColors.white,
        title: strings.title,
        right: { type: "cancel", onPress: () => screen.nav.pop(props.popCount) },
      };
    }, [screen.nav.pop]);

    return (
      <ScreenView header={header} scrollView={false} maxContentWidth>
        <View
          style={{
            width: "100%",
            height: 56,
            justifyContent: "center",
            paddingHorizontal: globalStyleConstants.unitSize,
            backgroundColor: "white",
            borderRadius: Number.MAX_SAFE_INTEGER,
            ...globalStyles.shadowTextInput,
          }}
        >
          <TextInput
            ref={textInputRef}
            value={inputText}
            onChangeText={setInputText}
            placeholderText={strings.placeholder}
            noBorder
          />
        </View>
        <Spacer vertical={1.5} />
        <ScrollView style={{ flex: 1 }} keyboardShouldPersistTaps="always">
          {existing
            .filter(s => s.toLowerCase().startsWith(inputText.toLowerCase()))
            .map(e => {
              return (
                <Pressable onPress={() => onSelect(e)} key={e}>
                  <View style={{ padding: globalStyleConstants.minPadding }}>
                    <TBody fontWeight="medium" color={globalStyleColors.colorAccentCool}>
                      {e}
                    </TBody>
                  </View>
                </Pressable>
              );
            })}
        </ScrollView>
        <InputAccessoryView backgroundColor="transparent" snapPoint={{ bottom: "tabBar" }}>
          <BottomActionBar
            primaryAction={{
              actionText: strings.save,
              onPressAction: onSave,
              waiting,
              singlePress: true,
            }}
            containerBackgroundColor="transparent"
            disableSnapToBottom
          />
        </InputAccessoryView>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: n => n.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => Number.parseInt(s),
    },
  }
);
