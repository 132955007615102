import { AdminBook, BookId, ExternalBookRecipe } from "@eatbetter/recipes-shared";
import { ButtonRectangle, globalStyleConstants, LeftAndRightButtons, Spacer } from "@eatbetter/ui-shared";
import { Divider } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Dimensions, View } from "react-native";
import { useNavigate } from "react-router-dom";
import { BookView } from "../components/BookView";
import { AdminScreenView } from "../components/AdminScreenView";
import { BookSearchSelect } from "../components/SearchSelect";
import { useDispatch } from "../lib/AdminRedux";
import { archiveBook, getBook, getBookRecipes, unArchiveBook } from "../lib/books/AdminBooksThunks";
import { AdminRecipeAddBookScreenNav } from "./AdminBookAddRecipeScreen";
import { AdminBookEditScreenNav } from "./AdminBookEditScreen";
import { AdminBookCreateScreenNav } from "./AdminBookCreateScreen";
import { useStringParam } from "../lib/ParamUtils";

export const AdminBooksScreenEmptyNav = {
  getPath: () => "/books",
};

const bookIdParam = ":bookId";
export const AdminBooksScreenIdNav = {
  getPath: (id?: string) => {
    return `${AdminBooksScreenEmptyNav.getPath()}/${id ?? bookIdParam}`;
  },
};

export const AdminBooksScreen = () => {
  const idParam = useStringParam(bookIdParam).optional();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [searchBookResult, setSearchBookResult] = useState<AdminBook | undefined>(undefined);
  const [selectedBook, setSelectedBook] = useState<{ book: AdminBook; recipes: ExternalBookRecipe[] }>();

  // on param change, search for the id if one is specified
  useEffect(() => {
    if (idParam === undefined) {
      setSearchBookResult(undefined);
      setSelectedBook(undefined);
    } else {
      Promise.all([dispatch(getBook(idParam as BookId)), dispatch(getBookRecipes(idParam as BookId))])
        .then(([bookResp, recipesResp]) => {
          if (bookResp.data) {
            setSelectedBook({ book: bookResp.data, recipes: recipesResp.data?.recipes ?? [] });
          }
        })
        .catch(err => {
          alert(`Error fetching book: ${JSON.stringify(err, undefined, 2)}`);
        });
    }
  }, [idParam]);

  useEffect(() => {
    // if the selection is undefined (i.e. no selectoin) and there is currently an ID, nav
    // to the empty book screen
    if (!searchBookResult && idParam) {
      nav(AdminBooksScreenEmptyNav.getPath());
    }

    // if there is a selection, but it's a different ID, nav there
    if (searchBookResult && searchBookResult.id !== idParam) {
      nav(AdminBooksScreenIdNav.getPath(searchBookResult.id));
    }
  }, [searchBookResult]);

  const onPressNewBook = useCallback(() => {
    nav(AdminBookCreateScreenNav.getPath());
  }, [nav]);

  const onPressEditBook = useCallback(() => {
    if (!selectedBook) {
      return;
    }
    nav(AdminBookEditScreenNav.getPath(selectedBook.book.id));
  }, [selectedBook, nav]);

  const toggleArchiveBook = useCallback(async () => {
    if (!selectedBook) {
      return;
    }

    // can't unarchive a book with mappedTo set
    if (selectedBook.book.mappedTo) {
      return;
    }

    if (selectedBook.book.archived) {
      await dispatch(unArchiveBook(selectedBook.book.id));
    } else {
      await dispatch(archiveBook(selectedBook.book.id));
    }

    setSelectedBook({
      book: { ...selectedBook.book, archived: !selectedBook.book.archived },
      recipes: selectedBook.recipes,
    });
  }, [dispatch, selectedBook, setSelectedBook]);

  const onPressAddRecipe = useCallback(() => {
    nav(AdminRecipeAddBookScreenNav.getPath(selectedBook?.book.id));
  }, [nav, selectedBook]);

  const screenHeight = Dimensions.get("window").height;

  return (
    <AdminScreenView>
      <View style={{ flex: 1, minHeight: screenHeight }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ width: 48 * globalStyleConstants.unitSize }}>
            <BookSearchSelect mode="single" selected={searchBookResult} onChange={setSearchBookResult} />
          </View>
          <Spacer horizontal={1} />
          <View style={{ paddingHorizontal: globalStyleConstants.unitSize }}>
            <ButtonRectangle onPress={onPressNewBook} type="submit" title="New Book" />
          </View>
        </View>
        <Spacer vertical={1} />
        {!!selectedBook && (
          <>
            <Divider />
            <View style={{ maxWidth: 36 * globalStyleConstants.unitSize }}>
              <LeftAndRightButtons
                rightButtonText="Edit Book"
                onPressRightButton={onPressEditBook}
                leftButtonText={selectedBook.book.archived ? "Unarchive Book" : "Archive Book"}
                onPressLeftButton={toggleArchiveBook}
                leftButtonDisabled={!!selectedBook.book.mappedTo}
                rightButtonDisabled={!!selectedBook.book.mappedTo}
              />
            </View>
            <Spacer vertical={1} />
            <BookView book={selectedBook.book} recipes={selectedBook.recipes} />
            <Spacer vertical={1} />
            {!selectedBook.book.archived && (
              <View style={{ width: 18 * globalStyleConstants.unitSize }}>
                <ButtonRectangle type="submit" title="Add Recipe" onPress={onPressAddRecipe} />
              </View>
            )}
          </>
        )}
      </View>
    </AdminScreenView>
  );
};
