import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import {
  EntityListItem,
  EntitySelectedHandler,
  SelectFollowableEntity,
  getFollowableEntities,
} from "../components/SelectEntity";
import { useDispatch } from "../lib/redux/Redux";
import { loadKnownEntities, searchUsers } from "../lib/users/UsersThunks";
import { log } from "../Log";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../components/SearchBox";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { HeaderProps } from "../components/ScreenHeaders";
import { navToEntityScreen } from "../lib/social/SocialThunks";
import { getSocialEntityId } from "@eatbetter/posts-shared";
import { LayoutAnimation, SectionList, SectionListData, SectionListRenderItem, StyleSheet, View } from "react-native";
import { bottomNop, bottomThrow } from "@eatbetter/common-shared";
import { Spacer } from "../components/Spacer";
import { TBody, TSecondary } from "../components/Typography";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { FlexedSpinner } from "../components/Spinner";
import { useKnownEntities, useUserSearchUsers } from "../lib/users/UsersSelectors";
import { navTree } from "../navigation/NavTree";
import { SearchKnownEntitiesNoResults, SearchUsersNoResults } from "../components/SearchEntities";
import { InviteFriendsTextButton } from "../components/InviteFriends";

const strings = {
  screenHeader: "Find People + Collections",
  searchPlaceholder: "Search people and collections",
  collections: "Collections",
  people: "People",
  viewAllCollections: (queryText: string) =>
    queryText.trim() ? "View All Collection Results" : "Browse All Collections",
  emptyState: "Start typing to search, or ",
};

export const SearchEntitiesScreen = withScreenContainer("SearchEntitiesScreen", () => {
  const [searchPhrase, onChangeSearchPhrase] = useState("");

  return React.createElement<Props>(SearchUsersScreenComponent, {
    searchPhrase,
    onChangeSearchPhrase,
  });
});

interface Props {
  searchPhrase: string;
  onChangeSearchPhrase: (v: string) => void;
}

type SectionType = "knownEntities" | "deglazeUsers";
interface Section {
  header?: { type: SectionType };
}

interface SectionItemBase<TType extends SectionType, TData> {
  type: TType;
  data: TData;
}
type KnownEntitySectionListItem = SectionItemBase<"knownEntities", SelectFollowableEntity>;
type DeglazeUsersSectionListItem = SectionItemBase<"deglazeUsers", SelectFollowableEntity>;
type SectionListItem = KnownEntitySectionListItem | DeglazeUsersSectionListItem;

type SearchEntitiesData = SectionListData<SectionListItem, Section>;

type RenderSectionPart = (info: { section: SearchEntitiesData }) => React.ReactElement;
type RenderSectionPartComponent = (props: {
  highlighted: boolean;
  section: Section;
  leadingSection: Section;
  trailingSection: Section;
  leadingItem: SectionListItem;
  trailingItem: SectionListItem;
}) => React.ReactElement;
type RenderSectionItem = SectionListRenderItem<SectionListItem, Section>;
type KeyExtractor = (item: SectionListItem, index: number) => string;

const SearchUsersScreenComponent = (props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();
  const keyboardHeight = useKeyboardLayoutAnimation();

  const userResults = useUserSearchUsers();
  const knownEntityResults = useKnownEntities(props.searchPhrase, 4);

  useEffect(() => {
    dispatch(loadKnownEntities()).catch(err =>
      log.errorCaught("Error calling loadKnownEntities on SearchUsersScreen mount", err)
    );
    dispatch(searchUsers("", { type: "searchUsers" })).catch(err =>
      log.errorCaught("Error calling searchUsers when on SearchUsersScreen mount", err)
    );
  }, []);

  const onChangeSearchPhrase = useCallback(
    (searchPhrase: string) => {
      if (screen.nav.focused) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      }
      props.onChangeSearchPhrase(searchPhrase);
      dispatch(searchUsers(searchPhrase, { type: "searchUsers" })).catch(err => {
        log.errorCaught("Unexpected error in SearchUsersScreen.onChangeSearchPhrase", err);
      });
    },
    [dispatch, screen.nav.focused, props.onChangeSearchPhrase]
  );

  const renderSearchBox = useCallback(() => {
    return (
      <SearchAndFilterBar
        searchPhrase={props.searchPhrase}
        onChangeSearchPhrase={onChangeSearchPhrase}
        placeholderText={strings.searchPlaceholder}
        autoCorrect={false}
      />
    );
  }, [props.searchPhrase, onChangeSearchPhrase]);

  const searchBoxHeight = useSearchAndFilterBarHeight();
  const getSearchBoxHeight = useCallback(() => {
    return searchBoxHeight;
  }, [searchBoxHeight]);

  const onPressEntity = useCallback<EntitySelectedHandler>(
    item => {
      dispatch(navToEntityScreen(getSocialEntityId(item.entity), screen.nav, "userSearch"));
    },
    [dispatch, screen.nav]
  );

  const sectionData = useMemo<SearchEntitiesData[]>(() => {
    return [
      {
        key: "knownEntities",
        header: { type: "knownEntities" },
        data: getFollowableEntities(knownEntityResults.entities, "entitySearch").map(i => ({
          type: "knownEntities",
          data: i,
        })),
      },
      {
        key: "deglazeUsers",
        header: { type: "deglazeUsers" },
        data: getFollowableEntities(userResults.users, "entitySearch").map(i => ({ type: "deglazeUsers", data: i })),
      },
    ];
  }, [knownEntityResults, userResults]);

  const renderItem = useCallback<RenderSectionItem>(
    ({ item }) => {
      return (
        <View style={styles.paddingHorizontal}>
          <EntityListItem item={item.data} onPressItem={onPressEntity} />
        </View>
      );
    },
    [onPressEntity]
  );

  const keyExtractor = useCallback<KeyExtractor>(item => {
    return getSocialEntityId(item.data.entity);
  }, []);

  const renderItemSeparator = useCallback<RenderSectionPartComponent>(() => {
    return <Spacer vertical={1} />;
  }, []);

  const renderSectionHeader = useCallback<RenderSectionPart>(
    info => {
      if (!info.section.header) {
        return <></>;
      }

      switch (info.section.header.type) {
        case "knownEntities": {
          return (
            <SectionHeader
              type="knownEntities"
              headerText={strings.collections}
              searchPhrase={props.searchPhrase}
              resultCount={knownEntityResults.entities.length}
              loading={knownEntityResults.loading}
            />
          );
        }
        case "deglazeUsers": {
          return (
            <SectionHeader
              type="deglazeUsers"
              headerText={strings.people}
              searchPhrase={props.searchPhrase}
              resultCount={userResults.users.length}
              loading={userResults.pending}
            />
          );
        }
        default: {
          bottomNop(info.section.header.type);
          return <></>;
        }
      }
    },
    [
      props.searchPhrase,
      knownEntityResults.entities.length,
      userResults.users.length,
      knownEntityResults.loading,
      userResults.pending,
    ]
  );

  const renderSectionSeparator = useCallback<RenderSectionPartComponent>(() => {
    return <Spacer vertical={1.5} />;
  }, []);

  const renderSectionFooter = useCallback<RenderSectionPart>(
    info => {
      const sectionKey = info.section.key as SectionType;
      switch (sectionKey) {
        case "knownEntities": {
          if (knownEntityResults.loading || knownEntityResults.entities.length < 4) {
            return <></>;
          }
          return (
            <View style={{ alignItems: "center" }}>
              <ViewAllCollectionsButton searchPhrase={props.searchPhrase} />
              <Spacer vertical={1.5} />
            </View>
          );
        }
        case "deglazeUsers": {
          return <></>;
        }
        default:
          bottomThrow(sectionKey);
      }
    },
    [props.searchPhrase, knownEntityResults.entities.length, knownEntityResults.loading]
  );

  const screenHeader = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      subHeaderComponent: {
        render: renderSearchBox,
        getHeight: getSearchBoxHeight,
      },
      title: strings.screenHeader,
      backgroundColor: "white",
    };
  }, [renderSearchBox, getSearchBoxHeight]);

  const containerPadding = useMemo(() => {
    return {
      paddingTop: headerHeight + searchBoxHeight + globalStyleConstants.unitSize,
      paddingBottom: keyboardHeight > 0 ? keyboardHeight : bottomTabBarHeight,
    };
  }, [headerHeight, searchBoxHeight, keyboardHeight, bottomTabBarHeight]);

  return (
    <ScreenView
      loading={userResults.pending && knownEntityResults.loading}
      header={screenHeader}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
    >
      <SectionList
        sections={sectionData}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={renderItemSeparator}
        renderSectionHeader={renderSectionHeader}
        SectionSeparatorComponent={renderSectionSeparator}
        renderSectionFooter={renderSectionFooter}
        stickySectionHeadersEnabled={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={containerPadding}
      />
    </ScreenView>
  );
};

const SectionHeader = React.memo(
  (props: { type: SectionType; headerText: string; searchPhrase: string; resultCount: number; loading: boolean }) => {
    const searchPhrase = props.searchPhrase.trim();

    return (
      <View style={styles.paddingHorizontal}>
        <TBody fontWeight="medium">{props.headerText}</TBody>
        {props.loading && props.resultCount === 0 && (
          <>
            <Spacer vertical={1.5} />
            <FlexedSpinner />
            <Spacer vertical={1.5} />
          </>
        )}
        {!props.loading && props.resultCount === 0 && (
          <>
            <Spacer vertical={1} />
            {!searchPhrase && (
              <>
                <TSecondary>
                  <TSecondary opacity="medium">{strings.emptyState}</TSecondary>
                  {props.type === "knownEntities" && <ViewAllCollectionsButton searchPhrase={props.searchPhrase} />}
                  {props.type === "deglazeUsers" && <InviteFriendsTextButton />}
                  <TSecondary>{"."}</TSecondary>
                </TSecondary>
              </>
            )}
            {!!searchPhrase && (
              <>
                {props.type === "knownEntities" && <SearchKnownEntitiesNoResults />}
                {props.type === "deglazeUsers" && <SearchUsersNoResults />}
              </>
            )}
            <Spacer vertical={3} />
          </>
        )}
      </View>
    );
  }
);

const ViewAllCollectionsButton = React.memo((props: { searchPhrase: string }) => {
  const screen = useScreen();

  const onPress = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.browseKnownEntities, {
      searchQuery: props.searchPhrase,
      analyticsContext: "searchEntitiesScreen",
    });
  }, [screen.nav.goTo, props.searchPhrase]);

  return (
    <TSecondary onPress={onPress} fontWeight="medium" color={globalStyleColors.colorTextLink} suppressHighlighting>
      {strings.viewAllCollections(props.searchPhrase)}
    </TSecondary>
  );
});

const styles = StyleSheet.create({
  paddingHorizontal: {
    paddingHorizontal: globalStyleConstants.defaultPadding,
  },
});
