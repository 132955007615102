import { AdminBook, ExternalBookRecipe } from "@eatbetter/recipes-shared";
import { Spacer, TBody, THeading2 } from "@eatbetter/ui-shared";
import { Divider } from "antd";
import React from "react";
import { View } from "react-native";
import { Link } from "react-router-dom";
import { AdminRecipesScreenIdNav } from "../screens/AdminRecipesScreen";
import { AuthorComponent, PhotoComponent } from "./AdminRecipeComponents";
import { NvpTable } from "./NvpTable";
import { AdminBooksScreenIdNav } from "../screens/AdminBooksScreen.tsx";

interface Props {
  book: AdminBook;
  recipes: ExternalBookRecipe[];
}

export const BookView = React.memo((props: Props) => {
  return (
    <View>
      <table>
        <NvpTable name="Title">
          <THeading2>{props.book.title}</THeading2>
        </NvpTable>
        <Spacer vertical={1} />
        <NvpTable name="ID">
          <TBody>{props.book.id}</TBody>
        </NvpTable>
        {!!props.book.archived && <NvpTable name="Archived" value backgroundColor="red" />}
        {!!props.book.mappedTo && (
          <NvpTable name="Mapped to" backgroundColor="yellow">
            <TBody>
              <Link to={AdminBooksScreenIdNav.getPath(props.book.mappedTo)} target="_blank">
                {props.book.mappedTo}
              </Link>
            </TBody>
          </NvpTable>
        )}
        <NvpTable name="ISBN">
          <TBody>{props.book.externalIds.isbn}</TBody>
        </NvpTable>
        <NvpTable name="ISBN10">
          <TBody>{props.book.externalIds.isbn10}</TBody>
        </NvpTable>
        <NvpTable name="Google Books ID">
          <TBody>{props.book.externalIds.googleBooksId}</TBody>
        </NvpTable>
        <NvpTable name="Mapped Editions">
          <TBody>{JSON.stringify(props.book.mappedEditions)}</TBody>
        </NvpTable>
        <NvpTable name="Purchase Link">
          <TBody>{props.book.purchaseLink}</TBody>
        </NvpTable>
        <Spacer vertical={1} />
        <NvpTable name="Photo">
          <PhotoComponent photo={props.book.coverPhoto} size="large" resizeMode="contain" />
        </NvpTable>
        <Divider />
        <NvpTable name="Primary Author">
          <AuthorComponent author={props.book.primaryAuthor} />
        </NvpTable>
        <NvpTable name="Other Authors">
          {props.book.otherAuthors?.map((i, idx) => {
            return (
              <View key={i.name + idx}>
                {idx !== 0 && <Spacer vertical={0.5} />}
                <AuthorComponent author={i} />
              </View>
            );
          })}
          <NvpTable name="Created" value={props.book.created} />
          <NvpTable name="Version" value={props.book.version} />
        </NvpTable>
        <Divider />
        <NvpTable name="Recipes">
          {props.recipes
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((i, idx) => {
              const link = AdminRecipesScreenIdNav.getPath(i.id);
              return (
                <View key={i.id + idx}>
                  {idx !== 0 && <Spacer vertical={1} />}
                  <TBody>
                    <Link to={link}>{i.title}</Link>
                  </TBody>
                </View>
              );
            })}
        </NvpTable>
      </table>
    </View>
  );
});
