import { ThunkAction } from "../redux/Redux";
import { SetWaitingHandler } from "../Types";
import { AnalyticsEventPropertyMap, GetShareDataArgs, ShareData } from "@eatbetter/composite-shared";
import { getLinkForNavigableScreen, NavApi } from "../../navigation/ScreenContainer";
import { log } from "../../Log";
import { navToAnonymousSigninIfAnon } from "../util/AnonymousSignIn";
import { ExternalSharedRecipeScreenProps, navTree } from "../../navigation/NavTree";
import {
  reportAppShareLinkCreated,
  reportPostShareLinkCreated,
  reportRecipeShareLinkCreated,
} from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { Share } from "../../components/Share";
import { AppRecipe, isUserRecipe, RecipeInfo, UserRecipeId } from "@eatbetter/recipes-shared";
import { getAppStoreLink } from "../util/AppStoreLink";
import { bottomNop, UrlString } from "@eatbetter/common-shared";
import { selectRecipe } from "../recipes/RecipesSelectors";
import { SocialPostId } from "@eatbetter/posts-shared";
import { selectUserId } from "../system/SystemSelectors";

const strings = {
  inviteFriendsMessage:
    "Follow me on Deglaze so we can share our favorite recipes! Deglaze lets you organize all your recipes in one place and create your grocery list in seconds.",
  shareAction: "share a recipe",
};

export const getShareData = (args: GetShareDataArgs, setWaiting?: SetWaitingHandler): ThunkAction<ShareData> => {
  return async (_dispatch, _getState, deps) => {
    const resp = await deps.api.withThrow(setWaiting).getShareData(args);
    return resp.data;
  };
};

export const shareAuthedUserProfileLink = (
  from: AnalyticsEventPropertyMap["App Share Link Created From"]
): ThunkAction<void> => {
  return async (dispatch, getState, _deps) => {
    log.info("Thunk: shareAppLink");

    const state = getState();
    const user = state.system.authedUser.data;

    try {
      const shareUrl = user?.isRegistered
        ? getLinkForNavigableScreen(navTree.get.screens.externalSharedRecipe, { userId: user.userId })
        : getAppStoreLink();

      const event = reportAppShareLinkCreated(from);
      dispatch(analyticsEvent(event));

      await Share.share({
        url: shareUrl,
        // Setting message means you can't copy/paste as a URL because it includes the message AND the link. This is expected
        // iOS behavior and fine if it's meant to be shared in a messaging app. If this is becomes an issue, we can add an
        // additional screen with buttons for "WhatsApp" and "Text Message" like other apps do (e.g. Duolingo, Whisk, etc), or
        // we can forego the message and let the link preview do all the work (e.g. Instagram).
        message: strings.inviteFriendsMessage,
      });
    } catch (err) {
      log.errorCaught("Unexpected error in shareAppLink", err);
      throw err;
    }
  };
};

export const sharePostLink = (args: { postId: SocialPostId }): ThunkAction<void> => {
  return async (dispatch, getState, _deps) => {
    try {
      const state = getState();
      const userId = selectUserId(state);
      if (!userId) {
        log.error("sharePostLink called with no user ID set");
        return;
      }

      const post = state.social.posts[args.postId];
      if (!post) {
        log.error(`sharePostLink called but post ${args.postId} not found in state`);
        return;
      }

      let props: ExternalSharedRecipeScreenProps;
      let recipe: RecipeInfo | undefined;
      switch (post.type) {
        case "userRecipeActionPost":
          props = { userId, sharedRecipeId: post.userRecipeId, sourceRecipeId: post.sourceRecipeId, postId: post.id };
          recipe = post.recipeInfo;
          break;
        case "newRecipePost":
          props = { userId, postId: post.id, sourceRecipeId: post.recipeInfo.id };
          recipe = post.recipeInfo;
          break;
        case "textPost":
          props = { userId, postId: post.id };
          break;
        default:
          bottomNop(post);
          return;
      }

      const shareUrl = getLinkForNavigableScreen(navTree.get.screens.externalSharedRecipe, props);
      const event = reportPostShareLinkCreated({ recipe, post });
      dispatch(analyticsEvent(event));

      await Share.share({ url: shareUrl });
    } catch (err) {
      log.errorCaught("Unexpected error in sharePostLink", err);
      throw err;
    }
  };
};

export const shareLibraryRecipeLink = (args: { recipeId: UserRecipeId; nav: NavApi }): ThunkAction<void> => {
  return async (dispatch, getState, _deps) => {
    const recipe = selectRecipe(getState(), args.recipeId);
    if (recipe && !recipe.archived && !recipe.deleted) {
      await dispatch(
        shareRecipeLink({
          recipe,
          nav: args.nav,
        })
      );
    }
  };
};

export const shareRecipeLink = (args: {
  recipe: AppRecipe;
  webViewUrl?: UrlString;
  nav: NavApi;
}): ThunkAction<void> => {
  return async (dispatch, getState, _deps) => {
    log.info("Thunk: shareRecipeLink");

    if (navToAnonymousSigninIfAnon(getState(), args.nav, strings.shareAction)) {
      return;
    }

    const state = getState();
    const userId = state.system.authedUser.data?.userId;
    if (!userId) {
      return;
    }

    try {
      const shareUrl = getLinkForNavigableScreen(navTree.get.screens.externalSharedRecipe, {
        userId,
        sharedRecipeId: args.recipe.id,
        // the source ID is used 1) when copying the recipe, although it is not strictly required here as the backend will get the source.
        // 2) In the app, when deciding where to direct the user. We need the source ID to determine if the user already has the recipe.
        sourceRecipeId: isUserRecipe(args.recipe) ? args.recipe.sourceRecipeId : undefined,
      });

      const event = reportRecipeShareLinkCreated({ recipe: args.recipe, webViewUrl: args.webViewUrl });
      dispatch(analyticsEvent(event));

      await Share.share({ url: shareUrl });
    } catch (err) {
      log.errorCaught("Unexpected error in shareRecipeLink", err);
      throw err;
    }
  };
};
