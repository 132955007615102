import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { ScreenView } from "../components/ScreenView";
import { withNonNavigableScreenContainer } from "../navigation/ScreenContainer";
import { BottomSheet, BottomSheetImperativeHandle } from "../components/BottomSheet";
import { BottomSheetScreenProps } from "../navigation/NavTree";
import { View } from "react-native";
import { smallScreenBreakpoint } from "../components/Responsive";
import { globalStyleConstants } from "../components/GlobalStyles";
import { Text } from "../components/Typography";
import { ButtonRectangle } from "../components/Buttons";

export const BottomSheetContext = React.createContext<BottomSheetImperativeHandle | null>(null);

export function useBottomSheet() {
  return useContext(BottomSheetContext);
}

export const BottomSheetScreen = withNonNavigableScreenContainer<BottomSheetScreenProps>("BottomSheetScreen", props => {
  const { content, ...bottomSheetProps } = props;

  const bottomSheetRef = useRef<BottomSheetImperativeHandle>(null);
  const [contextValue, setContextValue] = useState<BottomSheetImperativeHandle | null>(bottomSheetRef.current);

  useEffect(() => {
    if (bottomSheetRef.current) {
      setContextValue(bottomSheetRef.current);
    }
  }, [bottomSheetRef.current, props.height]);

  return (
    <ScreenView
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      sceneBackgroundColor="transparent"
      backgroundColor="transparent"
    >
      <BottomSheet {...bottomSheetProps} ref={bottomSheetRef}>
        <BottomSheetContext.Provider value={contextValue}>{props.content}</BottomSheetContext.Provider>
      </BottomSheet>
    </ScreenView>
  );
});

/**
 * A basic bottom sheet setup with a title and an action button
 */
export const BottomSheetContent = React.memo(
  (props: PropsWithChildren<{ title: string; actionButtonText: string; onPressActionButton: () => void }>) => {
    const bottomSheet = useBottomSheet();

    return (
      <View
        style={{
          height: bottomSheet?.getContentHeight(),
          maxWidth: smallScreenBreakpoint,
          width: "100%",
          alignSelf: "center",
          alignItems: "center",
          padding: globalStyleConstants.defaultPadding,
          justifyContent: "space-between",
        }}
      >
        <Text fontSize="body" fontWeight="medium" responsive>
          {props.title}
        </Text>
        {props.children}
        <ButtonRectangle
          type="submit"
          size="large"
          onPress={props.onPressActionButton}
          title={props.actionButtonText}
        />
      </View>
    );
  }
);
