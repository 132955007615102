import { RecipeInfo } from "@eatbetter/recipes-shared";
import React from "react";
import { ScreenView } from "../ScreenView";
import { RecipeDetailPhotos } from "../RecipePhoto";
import { RecipeDetailTitleAndSource } from "./RecipeTitleAndSource";
import { ContainerPadded } from "../Containers";
import { ScrollView } from "react-native-gesture-handler";
import { BuyBookButton } from "../Buttons";
import { bottomActionBarConstants } from "../BottomActionBar";
import { globalStyleConstants } from "../GlobalStyles";

export const RestrictedBookRecipeScreenComponent = React.memo((props: { recipe: RecipeInfo }) => {
  const title = props.recipe.title;
  const author = props.recipe.author;
  const publisher = props.recipe.publisher;
  const sourceType = props.recipe.source.type;
  const book = props.recipe.book;
  const userEnteredAttribution = props.recipe.userEnteredAttribution;

  return (
    <ScreenView
      scrollView={false}
      paddingHorizontal="none"
      paddingVertical="headerOnly"
      header={{ type: "default", title: "" }}
      backgroundColor="white"
    >
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: globalStyleConstants.minPadding,
          paddingTop: globalStyleConstants.minPadding,
          paddingBottom: bottomActionBarConstants.height,
        }}
        showsVerticalScrollIndicator={false}
      >
        <RecipeDetailPhotos recipe={props.recipe} isRestrictedBookPhoto onPress="expand" />
        <ContainerPadded horizontal={1} bottom={3}>
          <RecipeDetailTitleAndSource
            title={title}
            author={author}
            publisher={publisher}
            sourceType={sourceType}
            book={book}
            isSocialMediaRecipe={false}
            isRestrictedBookPhotoRecipe
            userEnteredAttribution={userEnteredAttribution}
          />
        </ContainerPadded>
      </ScrollView>
      {!!book?.purchaseLink && (
        <BuyBookButton type="snapToBottom" recipe={props.recipe} bookPurchaseLink={book.purchaseLink} />
      )}
    </ScreenView>
  );
});
