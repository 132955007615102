import React from "react";
import { View } from "react-native";
import { Separator } from "../Separator";
import { TBody, TSecondary } from "../Typography";

export const RecipeSectionHeading = React.memo(
  (props: {
    size?: "secondary" | "body";
    sectionIndex: number;
    text: string | undefined;
    scale?: number;
    enableFontScaling?: boolean;
  }) => {
    const enableFontScaling = props.scale || props.enableFontScaling ? "upOnly" : undefined;

    return (
      <>
        {(!!props.text || props.sectionIndex !== 0) && (
          <View style={{ flex: 1 }}>
            {!!props.text && (
              <>
                {props.size !== "secondary" && (
                  <TBody fontWeight="medium" scale={props.scale} enableFontScaling={enableFontScaling}>
                    {props.text}
                  </TBody>
                )}
                {props.size === "secondary" && (
                  <TSecondary fontWeight="medium" scale={props.scale} enableFontScaling={enableFontScaling}>
                    {props.text}
                  </TSecondary>
                )}
              </>
            )}
            {!props.text && (
              <View style={{ maxWidth: 96 }}>
                <Separator orientation="row" />
              </View>
            )}
          </View>
        )}
      </>
    );
  }
);
