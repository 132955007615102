import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { ScrollView, StyleSheet, View, ViewProps } from "react-native";
import { newId, switchReturn } from "@eatbetter/common-shared";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { FlexedSpinner } from "./Spinner";
import { getPullToRefresh } from "./PullToRefresh";
import { maxContentWidth, smallScreenBreakpoint } from "./Responsive";
import { activateKeepAwakeAsync, deactivateKeepAwake } from "expo-keep-awake";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  CustomHeader,
  CustomHeaderProps,
  useNativeHeaderOptions,
  HeaderProps,
  NativeHeaderProps,
  NoHeaderProps,
  ScreenHeaderContextProvider,
  useScreenHeaderDimensions,
  useDefaultHeaderProps,
} from "./ScreenHeaders";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { DocumentHeadEditProps } from "./DocumentHeadEdit/DocumentHeadInterfaces";
import { DocumentHeadEdit } from "./DocumentHeadEdit/DocumentHeadEdit";
import { Gradient } from "./Gradient/Gradient";
import { Platform } from "react-native";
import { log } from "../Log";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";

const defaults = {
  sceneBackgroundColor: globalStyleColors.white,
  screenBackgroundColor: globalStyleColors.colorGreyLight,
  webThemeColor: globalStyleColors.white,
};

export function useScreenElementDimensions() {
  return {
    ...useScreenHeaderDimensions(),
    ...useBottomTabBarDimensions(),
  };
}

interface Props {
  header?: HeaderProps;

  /**
   * Whether to add horizontal padding to the screen. False is an alias for "none" (back compat).
   */
  paddingHorizontal?: "default" | "min" | "none" | false;

  /**
   * Whether to add vertical padding to the screen. False is an alias for "none" (back compat).
   */
  paddingVertical?: "default" | "headerAndBottomTabBar" | "headerOnly" | "bottomTabBarOnly" | "none" | false;

  /**
   * If true, content width is constrained to a max (`smallScreenBreakpoint`) and centered. Otherwise, the
   * default max content width (`maxContentWidth`) is used.
   */
  maxContentWidth?: boolean;

  /**
   * Screen content container background color
   */
  backgroundColor?: string;

  /**
   * Scene content container background color (this is the "root" view; sits behind the screen content container)
   */
  sceneBackgroundColor?: string;

  /**
   * Whether or not to use a scrollview. Defaults to true.
   */
  scrollView?: boolean | { onPull: () => Promise<void> };

  /**
   * Prevents the display from going to sleep when the screen is in focus
   */
  keepAwake?: boolean;

  /**
   * If true, renders a spinner instead of children
   */
  loading?: boolean;

  webMeta?: DocumentHeadEditProps;

  /**
   * If true, adjusts default layout appropriately for differences in header measurement
   */
  isModal?: boolean;

  /**
   * Called when the tab button is pressed for the active tab
   */
  onTabPress?: () => void;
}

export const ScreenView = React.memo((props: PropsWithChildren<Props>) => {
  const nav = useNavigation<NativeStackNavigationProp<{}>>();
  const headerProps = useDefaultHeaderProps(props.header);

  const nativeHeaderOptions = useNativeHeaderOptions(headerProps, props.isModal);
  const sceneBackgroundColor = props.sceneBackgroundColor ?? defaults.sceneBackgroundColor;

  useLayoutEffect(() => {
    nav.setOptions({ ...nativeHeaderOptions, contentStyle: { backgroundColor: sceneBackgroundColor } });
  }, [nativeHeaderOptions, nav.setOptions]);

  useFocusEffect(
    useCallback(() => {
      if (!props.onTabPress) {
        return undefined;
      }

      const bottomTabNavProps = nav.getParent<BottomTabNavigationProp<{}>>();
      return bottomTabNavProps.addListener("tabPress", props.onTabPress);
    }, [nav, props.onTabPress])
  );

  const keepAwakeId = useRef(newId());

  // Toggle screen keep awake on only when the screen is focused
  useFocusEffect(
    useCallback(() => {
      if (props.keepAwake) {
        activateKeepAwakeAsync(keepAwakeId.current).catch(err =>
          log.errorCaught("Error calling activateKeepAwakeAsync", err)
        );
        return () =>
          deactivateKeepAwake(keepAwakeId.current).catch(err =>
            log.errorCaught("Error calling deactivateKeepAwake", err)
          );
      }

      return undefined;
    }, [props.keepAwake, keepAwakeId.current])
  );

  // Deactivate screen keep awake when the component unmounts for good measure
  useEffect(() => {
    return () => {
      if (props.keepAwake) {
        deactivateKeepAwake(keepAwakeId.current).catch(err =>
          log.errorCaught("Error calling deactivateKeepAwake on unmount", err)
        );
      }
    };
  }, []);

  return (
    <ScreenViewContainer backgroundColor={props.backgroundColor} headerProps={headerProps} webMeta={props.webMeta}>
      <ScreenViewContentContainer
        scrollView={props.scrollView}
        paddingHorizontal={props.paddingHorizontal}
        paddingVertical={props.paddingVertical}
        maxContentWidth={props.maxContentWidth}
        isModal={props.isModal}
      >
        {!!props.loading && <FlexedSpinner debugText="ScreenView" />}
        {!props.loading && props.children}
      </ScreenViewContentContainer>
    </ScreenViewContainer>
  );
});

const ScreenViewContainer = React.memo(
  (
    props: PropsWithChildren<{
      headerProps: CustomHeaderProps | NativeHeaderProps | NoHeaderProps;
      backgroundColor?: string;
      maxContentWidth?: boolean;
      webMeta?: DocumentHeadEditProps;
    }>
  ) => {
    const maxWidthStyle = useMemo<ViewProps["style"]>(() => {
      return {
        alignSelf: "center",
        width: "100%",
        maxWidth: props.maxContentWidth ?? Platform.OS === "web" ? maxContentWidth : undefined,
      };
    }, [props.maxContentWidth]);

    return (
      <ScreenHeaderContextProvider {...props.headerProps}>
        <DocumentHeadEdit
          themeColor={props.webMeta?.themeColor ?? defaults.webThemeColor}
          documentTitle={props.webMeta?.documentTitle}
        />
        <GestureHandlerRootView style={StyleSheet.absoluteFill}>
          <ScreenBackground backgroundColor={props.backgroundColor}>{props.children}</ScreenBackground>
          {/* https://github.com/Kureev/react-native-blur/issues/189#issuecomment-709857959 */}
          {/* This must come after the main view or blur will not work */}
          {props.headerProps.type === "custom" && (
            <View style={maxWidthStyle}>
              <CustomHeader {...props.headerProps} />
            </View>
          )}
        </GestureHandlerRootView>
      </ScreenHeaderContextProvider>
    );
  }
);

const ScreenBackground = React.memo((props: PropsWithChildren<{ backgroundColor?: string }>) => {
  const content = (
    <>
      {props.backgroundColor === undefined && <Gradient type="screenBackground">{props.children}</Gradient>}
      {props.backgroundColor !== undefined && props.children}
    </>
  );

  return <View style={[styles.background, { backgroundColor: props.backgroundColor }]}>{content}</View>;
});

const ScreenViewContentContainer = React.memo(
  (
    props: PropsWithChildren<{
      scrollView?: boolean | { onPull: () => Promise<void> };
      maxContentWidth?: Props["maxContentWidth"];
      paddingHorizontal?: Props["paddingHorizontal"];
      paddingVertical?: Props["paddingVertical"];
      isModal?: boolean;
    }>
  ) => {
    const { headerHeight, modalHeaderHeight, bottomTabBarHeight } = useScreenElementDimensions();

    const headerPadding = props.isModal ? modalHeaderHeight : headerHeight;

    const contentPaddingTop = globalStyleConstants.unitSize;
    const contentPaddingBottom = 2 * globalStyleConstants.unitSize;

    const paddingHorizontal =
      // Back compat - values support more than just boolean now but this prevents having to change every screen right now
      props.paddingHorizontal === false
        ? {}
        : switchReturn(props.paddingHorizontal ?? "default", {
            none: {},
            min: { paddingHorizontal: globalStyleConstants.minPadding },
            default: { paddingHorizontal: globalStyleConstants.defaultPadding },
          });

    const paddingVertical =
      // Back compat - values support more than just boolean now but this prevents having to change every screen right now
      props.paddingVertical === false
        ? {}
        : switchReturn(props.paddingVertical ?? "default", {
            none: {},
            headerOnly: { paddingTop: headerPadding },
            bottomTabBarOnly: { paddingBottom: bottomTabBarHeight },
            headerAndBottomTabBar: { paddingTop: headerPadding, paddingBottom: bottomTabBarHeight },
            default: {
              paddingTop: headerPadding + contentPaddingTop,
              paddingBottom: bottomTabBarHeight + contentPaddingBottom,
            },
          });

    const contentContainerStyle = useMemo<ViewProps["style"]>(
      () => [
        styles.contentContainer,
        // flex: 1 must stay or screen-level FlatLists won't scroll on web:
        // https://github.com/necolas/react-native-web/issues/1436#issuecomment-612845122
        // But on native, flex: 1 will prevent the ScrollView from scrolling
        Platform.OS === "web" ? { flex: 1 } : {},
        props.maxContentWidth
          ? { alignSelf: "center", maxWidth: smallScreenBreakpoint }
          : Platform.OS === "web"
          ? styles.maxContentWidth
          : {},
        paddingHorizontal,
        paddingVertical,
      ],
      [paddingHorizontal, paddingVertical, props.maxContentWidth, props.paddingHorizontal, props.paddingVertical]
    );

    const refreshControl = useMemo(() => {
      if (typeof props.scrollView === "object") {
        return getPullToRefresh(props.scrollView.onPull);
      }

      return undefined;
    }, [props.scrollView]);

    return (
      <>
        {props.scrollView === false && <View style={contentContainerStyle}>{props.children}</View>}
        {props.scrollView !== false && (
          <ScrollView
            contentContainerStyle={contentContainerStyle}
            refreshControl={refreshControl}
            keyboardShouldPersistTaps="handled"
            keyboardDismissMode="interactive"
          >
            {props.children}
          </ScrollView>
        )}
      </>
    );
  }
);

const styles = StyleSheet.create({
  background: {
    ...StyleSheet.absoluteFillObject,
  },
  contentContainer: {
    flexGrow: 1,
    width: "100%",
    justifyContent: "flex-start",
  },
  maxContentWidth: {
    maxWidth: maxContentWidth,
    alignSelf: "center",
  },
  paddingHorizontal: {
    paddingHorizontal: 20,
  },
});
