import { NavApi, useScreen, withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { Text } from "../components/Typography.tsx";
import { ScreenView } from "../components/ScreenView.tsx";
import { useEffect, useMemo } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles.ts";
import { AttributionQuestionsScreenProps, AttributionQuestionStep, navTree } from "../navigation/NavTree.ts";
import { AppUserRecipe, UserRecipeId } from "@eatbetter/recipes-shared";
import { useExistingBookAttribution, useLibraryRecipe } from "../lib/recipes/RecipesSelectors.ts";
import { Spacer } from "../components/Spacer.tsx";
import { Pressable } from "../components/Pressable.tsx";
import { View } from "react-native";
import { bottomNop } from "@eatbetter/common-shared";
import { useResponsiveDimensions } from "../components/Responsive.ts";
import React from "react";

const strings = {
  title: "Edit Recipe Source",
};

export const AttributionQuestionsScreen = withScreenContainer(
  "AttributionQuestionsScreen",
  (props: AttributionQuestionsScreenProps) => {
    const screen = useScreen();
    const recipe = useLibraryRecipe(props.recipeId);
    const responsive = useResponsiveDimensions();

    useEffect(() => {
      if (!isUserSourceRecipe(recipe)) {
        screen.nav.pop(props.popCount);
      }
    }, [recipe, screen.nav]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        backgroundColor: globalStyleColors.white,
        title: strings.title,
        right: { type: "cancel", onPress: () => screen.nav.pop(props.popCount) },
      };
    }, [screen.nav.goBack]);

    const existingBooks = useExistingBookAttribution();

    const qAndA = useMemo(
      () => getQAndA(recipe, props, screen.nav, existingBooks.length),
      [recipe, props.step, existingBooks.length]
    );

    if (!recipe || !qAndA) {
      // we'll redirect in useEffect above for no recipe, so just return an empty screen
      return <ScreenView />;
    }

    return (
      <ScreenView header={header} maxContentWidth>
        <Spacer vertical={1} />
        <View style={{ alignItems: "center" }}>
          <Text fontSize="h2" align="center" responsive>
            {qAndA.question}
          </Text>
          {!!qAndA.questionExplainer && (
            <React.Fragment>
              <Spacer vertical={0.25} />
              <Text fontSize="body" align="center" responsive>
                {qAndA.questionExplainer}
              </Text>
            </React.Fragment>
          )}
        </View>
        <Spacer vertical={1.5} />
        {qAndA.answers.map(a => {
          return (
            <Pressable onPress={a.onSelect} key={a.answer}>
              <View
                style={{
                  borderWidth: 1,
                  minHeight: responsive.getResponsiveHeight(56, 56),
                  justifyContent: "center",
                  paddingVertical: globalStyleConstants.unitSize,
                  paddingHorizontal: globalStyleConstants.defaultPadding,
                  marginBottom: globalStyleConstants.unitSize,
                  borderRadius: globalStyleConstants.defaultBorderRadius,
                  borderColor: globalStyleColors.rgba("colorAccentCool", "light"),
                }}
              >
                <Text fontSize="body" color={globalStyleColors.colorAccentCool} fontWeight="medium" responsive>
                  {a.answer}
                </Text>
                {!!a.explainer && (
                  <Text fontSize="tertiary" color={globalStyleColors.colorAccentCool} responsive>
                    {a.explainer}
                  </Text>
                )}
              </View>
            </Pressable>
          );
        })}
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      step: { optional: true, fn: s => s },
      popCount: n => n.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      step: { optional: true, fn: s => s as AttributionQuestionStep },
      popCount: s => Number.parseInt(s),
    },
  }
);

interface QuestionAndAnswers {
  question: string;
  questionExplainer?: string;
  answers: Array<{ answer: string; explainer?: string; onSelect: () => void }>;
}

function isUserSourceRecipe(r?: AppUserRecipe) {
  return r?.source.type === "user" || r?.source.type === "userPhoto";
}

function getQAndA(
  recipe: AppUserRecipe | undefined,
  props: AttributionQuestionsScreenProps,
  nav: NavApi,
  existingBookCount: number
): QuestionAndAnswers | undefined {
  if (!recipe || !isUserSourceRecipe(recipe)) {
    return undefined;
  }

  const sourceType = recipe.source.type;
  if (sourceType !== "user" && sourceType !== "userPhoto") {
    throw new Error("Unexpected source type");
  }

  let step = props.step;

  // we should only use this if there is a book or user entered book, but use a default just in case
  const bookTitle =
    recipe.book?.name ??
    (recipe.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution?.name : "the book");

  if (!step) {
    if (sourceType === "userPhoto") {
      const hasBook = !!recipe.book || recipe.userEnteredAttribution?.type === "book";
      const hasSimple = !!recipe.userEnteredAttribution;

      // fall through
      if (!hasBook && !hasSimple) {
        step = "whatType";
      } else if (hasBook) {
        step = "existingBookOptions";
      } else {
        // simple
      }
    }
  }

  if (!step) {
    return undefined;
  }

  if (step === "whatType") {
    return {
      question: "Is this recipe from a cookbook?",
      answers: [
        {
          answer: "Yes, it's from a book",
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.attributionQuestions, {
              ...props,
              step: "bookEntryOptions",
              popCount: props.popCount + 1,
            }),
        },
        {
          answer: "No, it's not from a book",
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.editSimpleAttribution, {
              recipeId: props.recipeId,
              popCount: props.popCount + 1,
            }),
        },
      ],
    };
  } else if (step === "existingBookOptions") {
    return {
      question: "What do you want to change?",
      questionExplainer: bookTitle,
      answers: [
        {
          answer: "Choose different book or source",
          explainer: "Replace book with a different book or source",
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.attributionQuestions, {
              recipeId: props.recipeId,
              step: "whatType",
              popCount: props.popCount + 1,
            }),
        },
        {
          answer: "Edit book details",
          explainer: "Edit current book details",
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.bookEdit, { recipeId: props.recipeId, popCount: props.popCount + 1 }),
        },
      ],
    };
  } else if (step === "bookEntryOptions") {
    const r = {
      question: "How do you want to enter\nthe book?",
      answers: [
        {
          answer: "Take a photo of the book cover",
          explainer: "We'll get the book details with AI ✨",
          onSelect: () =>
            nav.goTo("push", navTree.get.screens.bookCoverPhoto, {
              recipeId: props.recipeId,
              popCount: props.popCount + 1,
            }),
        },
        {
          answer: "Enter the title and author",
          onSelect: () =>
            // we are replacing the book in this context, so start with a blank form
            nav.goTo("push", navTree.get.screens.bookEdit, {
              recipeId: props.recipeId,
              popCount: props.popCount + 1,
              info: { name: "", author: "", photo: undefined },
            }),
        },
      ],
    };

    if (existingBookCount > 0) {
      r.answers.unshift({
        answer: "Choose from existing books",
        explainer: "Choose from existing books in my Recipe Library",
        onSelect: () =>
          nav.goTo("push", navTree.get.screens.bookSelection, {
            recipeId: props.recipeId,
            popCount: props.popCount + 1,
          }),
      });
    }

    return r;
  } else {
    bottomNop(step);
  }

  return undefined;
}
