import { useMemo } from "react";
import { useWindowDimensions } from "react-native";

export const maxContentWidth = 900;

export const smallScreenBreakpoint = 640;
export const largeScreenBreakpoint = 1200;

// iPhone 15 Pro
export const defaultScreenWidth = 393;
export const defaultScreenHeight = 852;
export const defaultScreenAspectRatio = defaultScreenHeight / defaultScreenWidth;

export function useResponsiveDimensions() {
  const window = useWindowDimensions();

  const defaultFontScale = Math.min(1.2, window.width / defaultScreenWidth);

  return useMemo(() => {
    return {
      ...window,
      isLargeScreen: window.width >= largeScreenBreakpoint,
      isMediumScreen: window.width > smallScreenBreakpoint && window.width < largeScreenBreakpoint,
      isSmallScreen: window.width <= smallScreenBreakpoint,
      /** Height divided by width */
      screenAspectRatio: window.height / window.width,
      defaultFontScale,
      getResponsiveHeight: (defaultHeight: number, minHeight?: number) =>
        Math.max(defaultHeight * (window.height / defaultScreenHeight), minHeight ?? 0),
      getResponsiveWidth: (defaultWidth: number) => defaultWidth * (window.width / defaultScreenWidth),
    };
  }, [window]);
}
