import { useScreen, withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { BookSelectScreenProps } from "../navigation/NavTree.ts";
import { EditUserRecipeAttributionArgs, UserRecipeId } from "@eatbetter/recipes-shared";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { ScreenView } from "../components/ScreenView.tsx";
import { useExistingBookAttribution, useLibraryRecipe } from "../lib/recipes/RecipesSelectors.ts";
import { Keyboard, View } from "react-native";
import { deepEquals, filterOutFalsy } from "@eatbetter/common-shared";
import { PhotoRef } from "@eatbetter/photos-shared";
import { BookAttribution } from "../lib/recipes/RecipesSlice.ts";
import { Pressable } from "../components/Pressable.tsx";
import { Spacer } from "../components/Spacer.tsx";
import { SelectedCircle } from "../components/SelectedCircle.tsx";
import { useDispatch } from "../lib/redux/Redux.ts";
import { editRecipeAttribution } from "../lib/recipes/RecipesThunks.ts";
import { displayUnexpectedErrorAndLog } from "../lib/Errors.ts";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../components/SearchBox.tsx";
import { AvatarAndHeading } from "../components/AvatarAndHeading.tsx";
import { globalStyleConstants } from "../components/GlobalStyles.ts";
import { ScrollViewWithSubmit } from "./ScrollViewWithSubmit.tsx";

const strings = {
  title: "Select Book",
  placeholder: "Search",
  save: "Save",
  by: "by",
};

export const BookSelectionScreen = withScreenContainer(
  "BookSelectionScreen",
  (props: BookSelectScreenProps) => {
    const screen = useScreen();
    const [filter, setFilter] = useState("");
    const [selected, setSelected] = useState<BookAttribution | undefined>();
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);

    const books = useExistingBookAttribution();
    const recipe = useLibraryRecipe(props.recipeId);

    useEffect(() => {
      if (books.length === 0 || !recipe) {
        screen.nav.pop(props.popCount);
      }
    }, [books, screen.nav]);

    const onSelect = useCallback(
      (tapped: BookAttribution) => {
        if (attributionEqual(selected, tapped)) {
          setSelected(undefined);
        } else {
          setSelected(tapped);
          Keyboard.dismiss();
        }
      },
      [setSelected, selected]
    );

    const onSave = useCallback(async () => {
      if (!selected || !recipe) {
        return;
      }

      const baseArgs: Pick<EditUserRecipeAttributionArgs, "recipeId" | "version"> = {
        recipeId: recipe.id,
        version: recipe.version,
      };

      const args: EditUserRecipeAttributionArgs =
        selected.type === "book"
          ? {
              ...baseArgs,
              type: "book",
              bookId: selected.book.id,
            }
          : {
              ...baseArgs,
              type: "userEntered",
              attribution: selected.attribution,
            };

      try {
        await dispatch(editRecipeAttribution(args, "Select Book Screen", setWaiting));
        screen.nav.pop(props.popCount);
      } catch (err) {
        displayUnexpectedErrorAndLog("Unexpected error dispatching editRecipeAttribution on BookSelectionScreen", err, {
          args,
        });
      }
    }, [recipe, selected, dispatch, setWaiting, screen.nav]);

    interface BookDisplay {
      name: string;
      author?: string;
      photo?: PhotoRef;
      value: BookAttribution;
    }
    const filtered = useMemo(() => {
      const display = books.map<BookDisplay>(b => {
        if (b.type === "book") {
          return { name: b.book.name, author: b.author, photo: b.book.photo, value: b };
        } else {
          return { name: b.attribution.name, author: b.attribution.author, photo: b.attribution.photo, value: b };
        }
      });

      if (filter.trim() === "") {
        return display;
      }

      const lcFilter = filter.toLowerCase();
      return display.filter(b => {
        const terms = [b.name, b.author];
        return filterOutFalsy(terms)
          .map(t => t.toLowerCase())
          .some(t => t.includes(lcFilter));
      });
    }, [books, filter]);

    const searchBarHeight = useSearchAndFilterBarHeight();

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "custom",
        backgroundColor: "white",
        title: strings.title,
        right: { type: "cancel", onPress: () => screen.nav.pop(props.popCount) },
        subHeaderComponent: {
          render: () => (
            <SearchAndFilterBar
              searchPhrase={filter}
              onChangeSearchPhrase={setFilter}
              placeholderText={strings.placeholder}
            />
          ),
          getHeight: () => searchBarHeight,
        },
      };
    }, [screen.nav.pop, searchBarHeight, filter, setFilter, props.popCount]);

    return (
      <ScreenView header={header} scrollView={false} paddingVertical={false}>
        <ScrollViewWithSubmit
          maxWidth={false}
          submitText={strings.save}
          onPressSubmit={onSave}
          submitDisabled={!selected}
          waiting={waiting}
        >
          {filtered.map((b, idx) => {
            return (
              <Pressable onPress={() => onSelect(b.value)} key={`${b.name} ${b.author} ${idx}`}>
                <View>
                  <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <AvatarAndHeading
                      avatar={{ photo: b.photo, size: "thumbnailLarge" }}
                      heading={{ text: b.name, numberOfLines: 2 }}
                      subHeading={{ text: `${strings.by} ${b.author}` }}
                    />
                    <View style={{ paddingLeft: globalStyleConstants.unitSize }}>
                      <SelectedCircle isSelected={attributionEqual(selected, b.value)} />
                    </View>
                  </View>
                  <Spacer vertical={1} />
                </View>
              </Pressable>
            );
          })}
        </ScrollViewWithSubmit>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: n => n.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => Number.parseInt(s),
    },
  }
);

function attributionEqual(a: BookAttribution | undefined, b: BookAttribution | undefined) {
  if (a === undefined || b === undefined) {
    return false;
  }

  return deepEquals(a, b);
}
