import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useEffect, useState } from "react";
import {
  selectPhotoFromCamera,
  selectPhotosFromLibrary,
  showCameraPermissionAlertAndNavToSettings,
} from "../lib/photos/SelectPhoto";
import { AppAddPhotoArgs } from "../lib/Types";
import { TSecondary } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { LayoutAnimation, ScrollView, StyleSheet, View } from "react-native";
import React from "react";
import { useDispatch } from "../lib/redux/Redux";
import { addRecipeFromPhotos } from "../lib/recipes/RecipesThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { isStructuredError, range } from "@eatbetter/common-shared";
import { smallScreenBreakpoint } from "../components/Responsive";
import { Haptics } from "../components/Haptics";
import { BottomActionBar, bottomActionBarConstants } from "../components/BottomActionBar";
import { Paragraph, ParagraphComponent } from "../components/LongformText";
import { Separator } from "../components/Separator";
import { navTree } from "../navigation/NavTree";
import { getOptionsMenuHeight } from "../components/OptionsMenu";
import { TextButton } from "../components/Buttons";
import { useCheckpointCompleted, useSystemSetting } from "../lib/system/SystemSelectors";
import { checkpointsCompleted } from "../lib/system/SystemSlice";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { reportPhotoIngestionExamplesButtonPressed } from "../lib/analytics/AnalyticsEvents";
import {
  AddPhotoOptionsSheet,
  AddPhotosButton,
  PhotoPlaceholder,
  SelectedPhoto,
} from "../components/TakeOrSelectPhotoComponents.tsx";
import { Alert } from "../components/Alert/Alert.ts";
import { RecipeImportQuotaExceededError } from "@eatbetter/recipes-shared";

const strings = {
  screenTitle: "Add a recipe from photos",
  submit: "Create Recipe",
  tips: "Photo tips for best results ✨",
  tipsBullets: (max: number) =>
    [
      {
        type: "text",
        text: `Add up to ${max} photos of the same recipe.`,
      },
      {
        type: "text",
        text: "Keep the page flat.",
      },
      {
        type: "text",
        text: "Take photos from directly overhead.",
      },
      {
        type: "text",
        text: "Make sure all text is legible.",
      },
    ] satisfies Paragraph["fragments"],
  seeExamples: "See Examples",
};

function layoutAnimation() {
  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
}

const defaultMaxPhotos = 3;

export const RecipeAddFromPhotosScreen = withScreenContainer("RecipeAddFromPhotosScreen", () => {
  const [selectedPhotos, setSelectedPhotos] = useState<AppAddPhotoArgs[]>([]);
  const [waitingSelectPhoto, setWaitingSelectPhoto] = useState<"camera" | "library" | null>(null);
  const [processingPhotos, setProcessingPhotos] = useState(0);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
  const [waitingSubmit, setWaitingSubmit] = useState(false);
  const dispatch = useDispatch();
  const { nav } = useScreen();
  const maxPhotos = useSystemSetting("recipePhotoIngestionMaxPhotos") ?? defaultMaxPhotos;

  const photoIngestionTipsCompleted = useCheckpointCompleted("photoIngestionOnboardingCompleted");

  const [quotaExceeded, setQuotaExceeded] = useState(false);

  const selectFromLibrary = useCallback(async () => {
    if (selectedPhotos.length >= maxPhotos) {
      return;
    }

    Haptics.feedback("itemStatusChanged");
    setWaitingSelectPhoto("library");
    const selected = await selectPhotosFromLibrary({
      maxCount: maxPhotos - selectedPhotos.length,
      ordered: true,
      onBeginProcessing: cnt => {
        setWaitingSelectPhoto(null);
        setProcessingPhotos(cnt);
      },
    });

    if (selected.success) {
      setSelectedPhotos(cur => [...cur, ...selected.result]);
    }

    setWaitingSelectPhoto(null);
    setProcessingPhotos(0);
  }, [setWaitingSelectPhoto, setProcessingPhotos, setSelectedPhotos, selectedPhotos]);

  const takePhoto = useCallback(async () => {
    setWaitingSelectPhoto("camera");
    const res = await selectPhotoFromCamera();
    if (res.success) {
      setSelectedPhotos(cur => [...cur, res.result]);
    }
    setWaitingSelectPhoto(null);

    if (!res.success && res.result.reason === "accessDenied") {
      showCameraPermissionAlertAndNavToSettings();
    }
  }, [setSelectedPhotos, setWaitingSelectPhoto]);

  const onCancelPhotoTipsFirstRun = useCallback(() => {
    // do nothing
  }, []);

  const onCompletePhotoTipsFirstRun = useCallback(() => {
    dispatch(checkpointsCompleted(["photoIngestionOnboardingCompleted"]));
    setTimeout(async () => await takePhoto(), 300);
  }, [dispatch, takePhoto]);

  const onPressTakePhoto = useCallback(async () => {
    if (selectedPhotos.length >= maxPhotos) {
      return;
    }

    Haptics.feedback("itemStatusChanged");

    if (!photoIngestionTipsCompleted) {
      nav.modal(navTree.get.screens.photoIngestionTips, {
        onCancel: onCancelPhotoTipsFirstRun,
        onComplete: onCompletePhotoTipsFirstRun,
      });
      return;
    }

    await takePhoto();
  }, [
    selectedPhotos,
    photoIngestionTipsCompleted,
    nav.modal,
    takePhoto,
    onCancelPhotoTipsFirstRun,
    onCompletePhotoTipsFirstRun,
  ]);

  const selectAddPhotoMethod = useCallback(() => {
    nav.modal(navTree.get.screens.bottomSheet, {
      content: <AddPhotoOptionsSheet takePhoto={onPressTakePhoto} selectFromLibrary={selectFromLibrary} mode="multi" />,
      height: getOptionsMenuHeight(2),
    });
  }, [nav.modal, onPressTakePhoto, selectFromLibrary]);

  const onUnselect = useCallback(
    (photo: AppAddPhotoArgs) => {
      Haptics.feedback("itemStatusChanged");
      layoutAnimation();
      setSelectedPhotos(cur => cur.filter(p => p.uri !== photo.uri));
    },
    [setSelectedPhotos]
  );

  const createRecipe = useCallback(async () => {
    try {
      const onProgress = (uri: string, progress: number) => {
        setUploadProgress(cur => ({ ...cur, [uri]: progress }));
      };

      Haptics.feedback("itemStatusChanged");
      await dispatch(addRecipeFromPhotos(selectedPhotos, onProgress, setWaitingSubmit));
      if (nav.canGoBack()) {
        nav.goBack();
      }
      Haptics.feedback("operationSucceeded");
    } catch (err) {
      if (isStructuredError(err) && err.data.userMessage) {
        Alert.alert(err.data.userMessage, "", [{ type: "save", text: "Okay", onPress: () => {} }]);

        const quotaExceededErrorCode: RecipeImportQuotaExceededError["code"] = "recipes/importQuotaExceeded";
        if (err.data.code === quotaExceededErrorCode) {
          setQuotaExceeded(true);
        } else {
          displayUnexpectedErrorAndLog("Error dispatching addRecipeFromPhotos", err);
        }
      }
    }
  }, [selectedPhotos, setUploadProgress, setWaitingSubmit, setQuotaExceeded]);

  return (
    <ScreenView
      header={{ type: "default", title: strings.screenTitle }}
      scrollView={false}
      paddingHorizontal="none"
      paddingVertical="headerAndBottomTabBar"
    >
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: globalStyleConstants.defaultPadding,
          paddingBottom: bottomActionBarConstants.height + 3 * globalStyleConstants.unitSize,
        }}
      >
        <View style={{ width: "100%", alignSelf: "center", maxWidth: smallScreenBreakpoint }}>
          <Spacer vertical={1.5} />
          <Tips maxPhotos={maxPhotos} />
          <Spacer vertical={1.5} />
          <Separator orientation="row" />
          <Spacer vertical={2} />
          <SelectedPhotos
            waitingSelectPhoto={!!waitingSelectPhoto}
            onPressAddPhoto={selectAddPhotoMethod}
            processingCount={processingPhotos}
            selectedPhotos={selectedPhotos}
            onUnselect={onUnselect}
            uploadProgress={uploadProgress}
            waitingSubmit={waitingSubmit}
            maxPhotos={maxPhotos}
          />
        </View>
      </ScrollView>
      <BottomActionBar
        primaryAction={{
          type: "submit",
          actionText: strings.submit,
          onPressAction: createRecipe,
          disabled: selectedPhotos.length === 0 || quotaExceeded,
          waiting: waitingSubmit,
        }}
        containerBackgroundColor="transparent"
      />
    </ScreenView>
  );
});

const SelectedPhotos = React.memo(
  (props: {
    waitingSelectPhoto: boolean;
    processingCount: number;
    selectedPhotos: AppAddPhotoArgs[];
    onUnselect: (photo: AppAddPhotoArgs) => void;
    onPressAddPhoto: () => void;
    uploadProgress: Record<string, number>;
    waitingSubmit: boolean;
    maxPhotos: number;
  }) => {
    const focused = useScreen().nav.focused;

    useEffect(() => {
      if (focused) {
        layoutAnimation();
      }
    }, [props.processingCount, props.selectedPhotos, props.uploadProgress, props.waitingSelectPhoto]);

    return (
      <View style={styles.container}>
        {props.selectedPhotos.map((sp, idx) => {
          return (
            <SelectedPhoto
              key={sp.uri}
              endOfRow={isEndOfRow(idx)}
              photo={sp}
              onDelete={props.onUnselect}
              uploadPercentComplete={props.uploadProgress[sp.uri]}
            />
          );
        })}
        {range(0, props.processingCount - 1).map(idx => {
          return (
            <PhotoPlaceholder key={`${idx}_placeholder`} endOfRow={isEndOfRow(props.selectedPhotos.length + idx)} />
          );
        })}
        {props.selectedPhotos.length + props.processingCount < props.maxPhotos && (
          <AddPhotosButton
            onPress={props.onPressAddPhoto}
            waiting={props.waitingSelectPhoto}
            disabled={props.waitingSubmit}
            mode="multi"
          />
        )}
      </View>
    );
  }
);

const Tips = React.memo((props: { maxPhotos: number }) => {
  const dispatch = useDispatch();
  const screen = useScreen();

  const onPressSeeExamples = useCallback(() => {
    dispatch(analyticsEvent(reportPhotoIngestionExamplesButtonPressed()));
    screen.nav.modal(navTree.get.screens.photoIngestionTips, { onCancel: () => {}, onComplete: () => {} });
  }, [dispatch, screen.nav.modal]);

  return (
    <>
      <TSecondary fontWeight="medium" opacity="dark">
        {strings.tips}
      </TSecondary>
      {strings.tipsBullets(props.maxPhotos).map((i, idx) => {
        return <ParagraphComponent key={idx} fragments={[i]} enumeration={idx + 1} textOpacity="dark" />;
      })}
      <Spacer vertical={1} />
      <TextButton
        text={strings.seeExamples}
        size="secondary"
        color={globalStyleColors.colorTextLink}
        bold
        onPress={onPressSeeExamples}
      />
    </>
  );
});

/**
 * Returns true if the provided index is the end of a photo row in selected photos
 */
function isEndOfRow(index: number): boolean {
  return (index + 1) % 3 === 0;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
