import { useScreen, withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { BookEditScreenProps } from "../navigation/NavTree.ts";
import { useCallback, useMemo, useState } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { globalStyleColors } from "../components/GlobalStyles.ts";
import { ScreenView } from "../components/ScreenView.tsx";
import { EditUserRecipeAttributionArgs, UserEnteredBookAttribution, UserRecipeId } from "@eatbetter/recipes-shared";
import { View } from "react-native";
import { useLibraryRecipe } from "../lib/recipes/RecipesSelectors.ts";
import { emptyToUndefined } from "@eatbetter/common-shared";
import { PhotoEditControl } from "../components/recipes/PhotoEditControl.tsx";
import { pickedPhotoToRef } from "../components/PhotoPicker.tsx";
import { AppAddPhotoArgs } from "../lib/Types.ts";
import { Spacer } from "../components/Spacer.tsx";
import { addPhoto } from "../lib/photos/PhotoThunks.ts";
import { useDispatch } from "../lib/redux/Redux.ts";
import { displayUnexpectedErrorAndLog } from "../lib/Errors.ts";
import { editRecipeAttribution } from "../lib/recipes/RecipesThunks.ts";
import { PhotoRef } from "@eatbetter/photos-shared";
import { RecipeEditTextInput } from "../components/recipes/RecipeEditTextInput.tsx";
import { SectionHeading } from "../components/SectionHeading.tsx";
import { ScrollViewWithSubmit } from "./ScrollViewWithSubmit.tsx";
import { Haptics } from "../components/Haptics.ts";

const strings = {
  title: "Edit Book Details",
};

export const BookEditScreen = withScreenContainer(
  "BookEditScreen",
  (props: BookEditScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const recipe = useLibraryRecipe(props.recipeId);

    const [title, setTitle] = useState(
      props.info?.name ??
        (recipe?.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.name : undefined) ??
        recipe?.book?.name ??
        ""
    );
    const [author, setAuthor] = useState(
      props.info?.author ??
        (recipe?.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.author : undefined) ??
        recipe?.author?.name ??
        ""
    );

    const [pickedCoverPhoto, setPickedCoverPhoto] = useState<AppAddPhotoArgs | undefined>();
    const [photoCleared, setPhotoCleared] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const existingPhoto =
      // if info is passed, use the photo, even if it's undefined.
      props.info
        ? props.info.photo
        : recipe?.book?.photo ??
          (recipe?.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.photo : undefined);

    const onPhotoCleared = useCallback(() => {
      Haptics.feedback("itemStatusChanged");
      setPickedCoverPhoto(undefined);
      setPhotoCleared(true);
    }, [setPhotoCleared]);

    const onSave = useCallback(async () => {
      if (!recipe || title.trim() === "") {
        return;
      }

      try {
        // if we're editing an existing book, or editing attribution that was originally from a book, keep the ID around
        const editedBookId =
          recipe?.book?.id ??
          (recipe?.userEnteredAttribution?.type === "book" ? recipe.userEnteredAttribution.editedBookId : undefined);

        setWaiting(true);
        let photo = photoCleared ? undefined : existingPhoto;
        if (pickedCoverPhoto) {
          photo = await dispatch(addPhoto(pickedCoverPhoto));
        }

        const attribution: UserEnteredBookAttribution = {
          type: "book",
          name: title,
          author: author,
          editedBookId,
          photo,
        };

        const args: EditUserRecipeAttributionArgs = {
          recipeId: recipe.id,
          version: recipe.version,
          type: "userEntered",
          attribution,
        };

        await dispatch(editRecipeAttribution(args, "Edit Book Screen"));
        screen.nav.pop(props.popCount);
      } catch (err) {
        displayUnexpectedErrorAndLog("Error in BookEditScreen onSave", err, { props });
      } finally {
        setWaiting(false);
      }
    }, [recipe, title, author, existingPhoto, screen.nav]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        backgroundColor: globalStyleColors.white,
        title: strings.title,
        right: { type: "cancel", onPress: () => screen.nav.pop(props.popCount) },
      };
    }, [screen.nav.goBack]);

    let photo: PhotoRef | undefined = existingPhoto;
    if (photoCleared) {
      photo = undefined;
    }
    if (pickedCoverPhoto) {
      photo = pickedPhotoToRef(pickedCoverPhoto);
    }

    return (
      <ScreenView header={header} scrollView={false} paddingVertical={false}>
        <ScrollViewWithSubmit
          submitText="Save"
          onPressSubmit={onSave}
          waiting={waiting}
          submitDisabled={!emptyToUndefined(title)}
        >
          <View style={{ width: "100%" }}>
            <SectionHeading text="Title" noPadding />
            <Spacer vertical={1} />
            <RecipeEditTextInput placeholderText="Book title" value={title} onChangeText={setTitle} />
            <Spacer vertical={2} />
            <SectionHeading text="Author" noPadding />
            <Spacer vertical={1} />
            <RecipeEditTextInput placeholderText="Book author" value={author} onChangeText={setAuthor} />
          </View>
          <Spacer vertical={2} />
          <View style={{ height: "20%" }}>
            <PhotoEditControl
              admin={false}
              sectionTitle="Cover Photo"
              photo={{ photoRef: photo, style: "bookCover" }}
              onPhotoPicked={setPickedCoverPhoto}
              onClear={onPhotoCleared}
            />
          </View>
        </ScrollViewWithSubmit>
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: n => n.toString(),
      info: { optional: true, fn: s => JSON.stringify(s) },
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => Number.parseInt(s),
      info: { optional: true, fn: s => JSON.parse(s) as Required<BookEditScreenProps>["info"] },
    },
  }
);
