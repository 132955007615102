import { useScreen, withScreenContainer } from "../navigation/ScreenContainer.tsx";
import { BookCoverPhotoScreenProps, navTree } from "../navigation/NavTree.ts";
import { Book, BookCoverInfo, UserRecipeId } from "@eatbetter/recipes-shared";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderProps } from "../components/ScreenHeaders.tsx";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles.ts";
import { ScreenView } from "../components/ScreenView.tsx";
import { Text, TSecondary } from "../components/Typography.tsx";
import { useDispatch } from "../lib/redux/Redux.ts";
import { BottomActionBar } from "../components/BottomActionBar.tsx";
import { AppAddPhotoArgs } from "../lib/Types.ts";
import { View } from "react-native";
import {
  AddPhotoOptionsSheet,
  AddPhotosButton,
  PhotoPlaceholder,
  SelectedPhoto,
} from "../components/TakeOrSelectPhotoComponents.tsx";
import { getOptionsMenuHeight } from "../components/OptionsMenu.tsx";
import { Haptics } from "../components/Haptics.ts";
import {
  selectPhotoFromCamera,
  selectPhotoFromLibrary,
  showCameraPermissionAlertAndNavToSettings,
} from "../lib/photos/SelectPhoto.ts";
import { editRecipeAttribution, getBookCoverInfo } from "../lib/recipes/RecipesThunks.ts";
import { displayUnexpectedErrorAndLog } from "../lib/Errors.ts";
import { Spacer } from "../components/Spacer.tsx";
import { useLibraryRecipe } from "../lib/recipes/RecipesSelectors.ts";
import { Photo } from "../components/Photo.tsx";
import { SubmitAndCancelButtons } from "../components/Buttons.tsx";
import { ScrollView } from "react-native-gesture-handler";
import { smallScreenBreakpoint } from "../components/Responsive.ts";

const strings = {
  title: "Add a book from a photo",
  headline: "Add a photo of a book cover",
  subheadline: "We'll use AI to get the book details",
  submit: "Get Book Details ✨",
  slow: "Hang in there. This can take up to 30 seconds.",
  noResults:
    "We couldn't find a title and author in that photo. You can try again or go back and select a different way to enter it.",
  acceptQuestion: "Is this the right book?",
  accept: "Yes",
  reject: "No",
  by: "by",
};

export const BookCoverPhotoScreen = withScreenContainer(
  "BookCoverPhotoScreen",
  (props: BookCoverPhotoScreenProps) => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [selectedPhoto, setSelectedPhoto] = useState<AppAddPhotoArgs>();
    const [waitingSelectPhoto, setWaitingSelectPhoto] = useState(false);
    const [waitingSubmit, setWaitingSubmit] = useState(false);
    const [photoIsProcessing, setPhotoIsProcessing] = useState(false);
    const [uploadPercent, setUploadPercent] = useState<number>();
    const [bookResult, setBookResult] = useState<BookCoverInfo>();
    const recipe = useLibraryRecipe(props.recipeId);

    useEffect(() => {
      if (!recipe) {
        screen.nav.pop(props.popCount);
      }
    }, [recipe, screen.nav]);

    const resetScreen = () => {
      setError("");
      setSelectedPhoto(undefined);
      setWaitingSubmit(false);
      setWaitingSelectPhoto(false);
      setPhotoIsProcessing(false);
      setUploadPercent(undefined);
    };

    const onSubmit = useCallback(async () => {
      setError("");
      if (!selectedPhoto) {
        return;
      }

      try {
        const resp = await dispatch(
          getBookCoverInfo(props.recipeId, selectedPhoto, setUploadPercent, setWaitingSubmit)
        );

        if (resp.bookAndAuthor) {
          setBookResult(resp);
        } else if (resp.titleAndAuthor) {
          // if we don't have an actual book, just nav to the edit book screen with what
          // we do have so they can save/edit it.
          screen.nav.goTo("push", navTree.get.screens.bookEdit, {
            recipeId: props.recipeId,
            popCount: props.popCount + 1,
            info: {
              name: resp.titleAndAuthor.title,
              author: resp.titleAndAuthor.author,
              photo: resp.photo,
            },
          });
          resetScreen();
        } else {
          setError(strings.noResults);
          setSelectedPhoto(undefined);
        }
      } catch (err) {
        displayUnexpectedErrorAndLog("Unexpected error in onSubmit in BookCoverPhotoScreen", err);
      }
    }, [dispatch, selectedPhoto]);

    const onAcceptBookResult = useCallback(async () => {
      if (!bookResult?.bookAndAuthor || !recipe) {
        return;
      }

      try {
        setWaitingSubmit(true);
        await dispatch(
          editRecipeAttribution(
            {
              recipeId: recipe.id,
              version: recipe.version,
              type: "book",
              bookId: bookResult.bookAndAuthor.book.id,
            },
            "Book Cover Photo Screen",
            setWaitingSubmit
          )
        );
        screen.nav.pop(props.popCount);
      } catch (err) {
        displayUnexpectedErrorAndLog("Unexpected error in onAcceptBookResult", err);
      }
    }, [bookResult, recipe, screen.nav]);

    // If the user rejects the book we found, take them to the edit book screen
    // with what we parsed
    const onRejectBookResult = useCallback(async () => {
      let name: string | undefined;
      let author: string | undefined;

      if (bookResult?.titleAndAuthor) {
        name = bookResult.titleAndAuthor.title;
        author = bookResult.titleAndAuthor.author;
      } else if (bookResult?.bookAndAuthor) {
        name = bookResult.bookAndAuthor.book.name;
        author = bookResult.bookAndAuthor.author;
      }

      if (!name) {
        return;
      }

      screen.nav.goTo("push", navTree.get.screens.bookEdit, {
        recipeId: props.recipeId,
        popCount: props.popCount + 1,
        info: {
          name,
          author,
          photo: bookResult?.photo,
        },
      });
    }, [bookResult, screen.nav]);

    const onDeletePhoto = useCallback(() => {
      setSelectedPhoto(undefined);
    }, [setSelectedPhoto]);

    const onPressTakePhoto = useCallback(async () => {
      if (selectedPhoto) {
        return;
      }

      Haptics.feedback("itemStatusChanged");

      setWaitingSelectPhoto(true);
      const res = await selectPhotoFromCamera();
      if (res.success) {
        setSelectedPhoto(res.result);
      }
      setWaitingSelectPhoto(false);

      if (!res.success && res.result.reason === "accessDenied") {
        showCameraPermissionAlertAndNavToSettings();
      }
    }, [selectedPhoto]);

    const selectFromLibrary = useCallback(async () => {
      if (selectedPhoto) {
        return;
      }

      Haptics.feedback("itemStatusChanged");
      setWaitingSelectPhoto(true);
      const selected = await selectPhotoFromLibrary({
        onBeginProcessing: () => {
          setWaitingSelectPhoto(false);
          setPhotoIsProcessing(true);
        },
      });

      if (selected.success) {
        setSelectedPhoto(selected.result);
      }

      setWaitingSelectPhoto(false);
      setPhotoIsProcessing(false);
    }, [setWaitingSelectPhoto, setPhotoIsProcessing, setSelectedPhoto, selectedPhoto]);

    const selectAddPhotoMethod = useCallback(() => {
      setError("");
      screen.nav.modal(navTree.get.screens.bottomSheet, {
        content: (
          <AddPhotoOptionsSheet takePhoto={onPressTakePhoto} selectFromLibrary={selectFromLibrary} mode="multi" />
        ),
        height: getOptionsMenuHeight(2),
      });
    }, [screen.nav.modal, onPressTakePhoto, selectFromLibrary]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "native",
        backgroundColor: globalStyleColors.white,
        title: strings.title,
        right: { type: "cancel", onPress: () => screen.nav.pop(props.popCount) },
      };
    }, [screen.nav.pop]);

    return (
      <ScreenView header={header} scrollView={false}>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            paddingTop: globalStyleConstants.unitSize,
            width: "100%",
            maxWidth: smallScreenBreakpoint,
            alignSelf: "center",
          }}
        >
          {!bookResult?.bookAndAuthor && (
            <View style={{ alignItems: "center" }}>
              <Text fontSize="h2" align="center" responsive>
                {strings.headline}
              </Text>
              <Text fontSize="secondary" align="center" responsive>
                {strings.subheadline}
              </Text>
              <Spacer vertical={2} />
              {!selectedPhoto && !photoIsProcessing && (
                <AddPhotosButton
                  onPress={selectAddPhotoMethod}
                  waiting={waitingSelectPhoto}
                  disabled={waitingSubmit}
                  mode="single"
                />
              )}
              {!selectedPhoto && photoIsProcessing && <PhotoPlaceholder endOfRow />}
              {selectedPhoto && (
                <SelectedPhoto
                  photo={selectedPhoto}
                  endOfRow
                  onDelete={onDeletePhoto}
                  uploadPercentComplete={uploadPercent}
                />
              )}
              <View style={{ alignItems: "center" }}>
                {waitingSubmit && <TSecondary>{strings.slow}</TSecondary>}
                {!waitingSubmit && !!error && (
                  <TSecondary align="center" color={globalStyleColors.colorError}>
                    {error}
                  </TSecondary>
                )}
              </View>
            </View>
          )}
          {!!bookResult?.bookAndAuthor && (
            <BookResult
              book={bookResult.bookAndAuthor.book}
              author={bookResult.bookAndAuthor.author}
              onAccept={onAcceptBookResult}
              onReject={onRejectBookResult}
              waiting={waitingSubmit}
            />
          )}
        </ScrollView>
        {!bookResult?.bookAndAuthor && (
          <BottomActionBar
            primaryAction={{
              type: "submit",
              actionText: strings.submit,
              onPressAction: onSubmit,
              disabled: !selectedPhoto,
              waiting: waitingSubmit,
            }}
            containerBackgroundColor="transparent"
          />
        )}
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
      popCount: n => n.toString(),
    },
    parser: {
      recipeId: s => s as UserRecipeId,
      popCount: s => Number.parseInt(s),
    },
  }
);

const BookResult = (props: {
  book: Book;
  author?: string;
  onAccept: () => void;
  onReject: () => void;
  waiting: boolean;
}) => {
  return (
    <View style={{ flex: 1, alignItems: "center" }}>
      <Text fontSize="h2" align="center" responsive>
        {strings.acceptQuestion}
      </Text>
      <Spacer vertical={1.5} />
      <View style={{ height: "50%", width: "100%" }}>
        <Photo source={props.book.photo} resizeMode="contain" style="flexed" sourceSize="w288" />
      </View>
      <Spacer vertical={1.5} />
      <Text fontSize="body" align="center" responsive>
        {props.book.name}
      </Text>
      {!!props.author && (
        <Text fontSize="secondary" align="center" responsive>
          {strings.by} {props.author}
        </Text>
      )}
      <View style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
        <SubmitAndCancelButtons
          onSubmit={props.onAccept}
          onCancel={props.onReject}
          waitingForSubmit={props.waiting}
          submitText={strings.accept}
          cancelText={strings.reject}
        />
      </View>
    </View>
  );
};
