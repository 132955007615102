import { useScreen, withNonNavigableScreenContainer } from "../../navigation/ScreenContainer";
import { ScreenView } from "../../components/ScreenView";
import { useCallback, useMemo, useRef } from "react";
import { useDispatch } from "../../lib/redux/Redux";
import { onboardingQuestionAnswered } from "../../lib/system/SystemSlice";
import { onboardingScreenHeader } from "../../components/onboarding/OnboardingScreenHeader";
import { OnboardingScreenHeadline } from "../../components/onboarding/OnboardingHeadline";
import { useSurveyOptions } from "../../components/SurveyOptions";
import { OnboardingCtaButton } from "../../components/onboarding/OnboardingCtaButton";
import { View } from "react-native";
import { bottomActionBarConstants } from "../../components/BottomActionBar";
import { navToNextOnboardingScreen } from "../../navigation/NavThunks";
import { navTree } from "../../navigation/NavTree";
import { Haptics } from "../../components/Haptics";
import { useAppMetadata } from "../../lib/system/SystemSelectors";
import { getRandomizedAppDiscoverySources } from "@eatbetter/composite-shared/src/AppDiscoverySourceTypes";
import { log } from "../../Log";

const strings = {
  headline: "How did you discover Deglaze?",
  subhead: "Your insights help make Deglaze better.",
  otherLabel: "How did you discover Deglaze?",
  otherInputPlaceholder: "Tell us how we met...",
};

export const OnboardingDiscoverySourceScreen = withNonNavigableScreenContainer(
  "OnboardingDiscoverySourceScreen",
  () => {
    const { nav } = useScreen();
    const dispatch = useDispatch();

    // Fetch the survey options from app metadata
    const serverOptionsRaw = useAppMetadata()?.appDiscoverySources?.randomizedSources;

    // Validate that options coming back from app metadata are within the length boundaries (exist, not empty and not too many).
    // If they are invalid, ditch them and fall back to the client list.
    const serverOptions = useMemo(() => {
      if (!serverOptionsRaw) {
        log.error("App discovery sources from app metadata is undefined. Falling back to client source list");
        return undefined;
      }

      if (serverOptionsRaw.length === 0 || serverOptionsRaw.length > 7) {
        log.error(
          `App discovery sources from app metadata have unsupported count (${serverOptionsRaw.length}). Falling back to client source list`,
          { discoverySourcesLength: serverOptionsRaw.length }
        );
        return undefined;
      }

      log.info("Successfully fetched app discovery sources from app metadata", { serverOptionsRaw });
      return serverOptionsRaw;
    }, [serverOptionsRaw]);

    // Snapshot survey options on initial render to prevent the order changing if the options refresh.
    // Use the survey options from app metadata, but fall back to client options if we don't have app metadata
    // for any reason - we never want to block during onboarding.
    const randomizedSurveyOptions = useRef(serverOptions ?? getRandomizedAppDiscoverySources());

    const surveyOptionsState = useSurveyOptions("singleSelect", randomizedSurveyOptions.current);

    const next = useCallback(
      (otherText?: string) => {
        if (!otherText) {
          // Already called from the onSubmitOther callback (for instant feedback), so only call if it's not other here
          Haptics.feedback("itemStatusChanged");
        }

        const discoverySource = surveyOptionsState.selected[0]?.key;

        dispatch(
          onboardingQuestionAnswered({
            discoverySource,
            discoverySourceOtherText: otherText,
            discoverySourceSurveyIndex: randomizedSurveyOptions.current.findIndex(i => i.key === discoverySource),
          })
        );
        dispatch(navToNextOnboardingScreen(nav, "replace"));
      },
      [dispatch, surveyOptionsState.selected]
    );

    const onSubmitOther = useCallback(
      (otherText: string) => {
        Haptics.feedback("itemStatusChanged");
        setTimeout(() => {
          next(otherText);
        }, 300);
      },
      [next]
    );

    const onNext = useCallback(() => {
      if (surveyOptionsState.selected[0]?.key === "other") {
        nav.modal(navTree.get.screens.surveyOtherOptionForm, {
          label: strings.otherLabel,
          textInputPlaceholder: strings.otherInputPlaceholder,
          onSubmit: onSubmitOther,
        });
      } else {
        next();
      }
    }, [surveyOptionsState.selected, nav.modal, next]);

    return (
      <ScreenView scrollView={false} header={onboardingScreenHeader}>
        <View
          style={{
            flex: 1,
            paddingBottom: bottomActionBarConstants.height,
            justifyContent: "space-between",
          }}
        >
          <View>
            <OnboardingScreenHeadline headline={strings.headline} subHeadline={strings.subhead} />
          </View>
          <View>{surveyOptionsState.renderSurveyOptions()}</View>
        </View>
        <OnboardingCtaButton
          cta="next"
          background="transparent"
          disabled={surveyOptionsState.selected.length === 0}
          onPress={onNext}
        />
      </ScreenView>
    );
  }
);
