import { AppUserRecipe, UserEnteredAttribution, UserRecipeId } from "@eatbetter/recipes-shared";
import { bottomWithDefault, toBasicLatin } from "@eatbetter/common-shared";

const titleMatch = 2;
const otherMatch = 1;

/**
 * Returns a map with recipe IDs that match the search query as the keys, and the relevance number as the value.
 * Higher relevance is better than lower relevance. Currently, title matches are ranked above everything else.
 * @param recipes
 * @param query
 */
export function searchRecipes(recipes: AppUserRecipe[], query: string): Record<UserRecipeId, number> {
  const results: Record<UserRecipeId, number> = {};
  const search = getSearchFn(query);
  recipes.forEach(r => {
    if (r.deleted || r.archived) {
      return;
    }

    if (search(r.title)) {
      results[r.id] = titleMatch;
      return;
    }

    if (
      search(r.author?.name) ||
      search(r.book?.name) ||
      search(r.publisher?.name) ||
      searchIngredients(r, search) ||
      searchUserEnteredAttribution(search, r.userEnteredAttribution)
    ) {
      results[r.id] = otherMatch;
      return;
    }
  });

  return results;
}

function searchUserEnteredAttribution(search: (s: string | undefined) => boolean, u?: UserEnteredAttribution): boolean {
  if (!u) {
    return false;
  }

  switch (u.type) {
    case "simple":
      return search(u.name);
    case "book":
      return search(u.name) || search(u.author);
    default:
      return bottomWithDefault(u, false, "searchUserEnteredAttribution");
  }
}

function searchIngredients(r: AppUserRecipe, search: (s?: string) => boolean): boolean {
  for (const section of r.ingredients.sections) {
    if (search(section.title)) {
      return true;
    }

    for (const item of section.items) {
      if (search(item.text)) {
        return true;
      }
    }
  }

  return false;
}

export function getSearchFn(query: string): (s?: string) => boolean {
  const searchTerms = normalizeAndSplit(query);

  return (textToSearch: string | undefined) => {
    if (!textToSearch) {
      return false;
    }

    const textTokens = normalizeAndSplit(textToSearch);
    return searchTerms.every(searchTerm => {
      return textTokens.some(tt => tt.startsWith(searchTerm));
    });
  };
}

function normalizeAndSplit(s: string): string[] {
  return normalize(s).split(/\s+/);
}

function normalize(str: string): string {
  return toBasicLatin(str).toLowerCase();
}
