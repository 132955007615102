import { bottomThrow, getUrlHostDisplayName, switchReturn } from "@eatbetter/common-shared";
import { PhotoRef } from "@eatbetter/photos-shared";
import {
  Author,
  Book,
  KnownAuthor,
  Publisher,
  RecipeSource,
  UnknownAuthor,
  UserEnteredAttribution,
} from "@eatbetter/recipes-shared";
import { DeglazeUser } from "@eatbetter/users-shared";
import React, { useCallback, useMemo, useState } from "react";
import { useNavToProfileScreen } from "../../lib/util/UseNavToProfileScreen";
import { Opacity, globalStyleColors, globalStyleConstants } from "../GlobalStyles";
import { Photo, PhotoSizeName } from "../Photo";
import { Pressable } from "../Pressable";
import { Spacer } from "../Spacer";
import { TSecondary, THeading1, TTertiary, TBody, THeading2 } from "../Typography";
import { Platform, StyleSheet, View } from "react-native";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { ButtonRectangle } from "../Buttons";
import { useDispatch } from "../../lib/redux/Redux";
import { reportIssue } from "../../lib/system/SystemThunks";
import { useBottomSheet } from "../../screens/BottomSheetScreen";
import { Haptics } from "../Haptics";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { useToast } from "../Toast";
import { navToEntityScreen } from "../../lib/social/SocialThunks";
import { openWebpage } from "../../lib/util/WebUtil";
import { IconAlert, IconCamera, IconInfo } from "../Icons";

const strings = {
  authorPrefix: {
    by: "by",
    addedBy: "added by",
  },
  from: "from",
  unknownEntitySheet: {
    headline: (name: string) => `We don't have a page for recipes by ${name}`,
    subhead: (name: string) => `If you want to follow and search recipes\nby ${name}, let us know!`,
    cta: (name: string) => `Request page for ${name}`,
    toast: "Your request was submitted, thanks!",
  },
  importedFromPhotos: "Imported from photos",
  aiWarning: "This recipe was imported with AI. Check for accuracy before using.",
  restrictedRecipe: [
    "The contents of this recipe are hidden to protect intellectual property. If you already own this book, you can ",
    "import recipes to Deglaze by taking a photo.",
  ],
};

interface RecipeTitleProps {
  title: string;
  fontSize: "h1" | "body" | "secondary";
  numberOfLines: number | "unlimited";
  adjustsFontSizeToFit?: boolean;
}

export const RecipeTitle = React.memo((props: RecipeTitleProps) => {
  const numberOfLines = props.numberOfLines === "unlimited" ? undefined : props.numberOfLines;

  return (
    <>
      {props.fontSize === "h1" && (
        <THeading1 numberOfLines={numberOfLines} adjustsFontSizeToFit={props.adjustsFontSizeToFit}>
          {props.title}
        </THeading1>
      )}
      {props.fontSize === "body" && (
        <TBody numberOfLines={numberOfLines} adjustsFontSizeToFit={props.adjustsFontSizeToFit} lineHeight={1.2 * 17}>
          {props.title}
        </TBody>
      )}
      {props.fontSize === "secondary" && (
        <TSecondary
          numberOfLines={numberOfLines}
          adjustsFontSizeToFit={props.adjustsFontSizeToFit}
          lineHeight={1.2 * 15}
        >
          {props.title}
        </TSecondary>
      )}
    </>
  );
});

interface RecipePublisherOrBookProps {
  author: Author | undefined;
  publisher: Publisher | undefined;
  book: Book | undefined;
  userEnteredAttribution: UserEnteredAttribution | undefined;
  sourceType: RecipeSource["type"];
  photoStyle: "square" | "circle";
  fontSize: "body" | "secondary" | "tertiary";
  numberOfLines: number | "unlimited";
  tapEnabled?: boolean;
  showFromPrefix?: boolean;
}

export const RecipePublisherOrBook = React.memo((props: RecipePublisherOrBookProps) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const navToUnknownEntitySheet = useNavToUnknownEntitySheet(props.publisher?.name);
  const knownPublisherId = props.publisher?.type === "knownPublisher" ? props.publisher.id : props.publisher?.id;
  const userEnteredBook = props.userEnteredAttribution?.type === "book" ? props.userEnteredAttribution : undefined;
  const bookOrUserEnteredBook = props.book ?? userEnteredBook;

  const onPressKnownPublisher = useCallback(() => {
    if (knownPublisherId) {
      dispatch(navToEntityScreen(knownPublisherId, screen.nav, "recipeDetail"));
    }
  }, [dispatch, screen.nav, knownPublisherId]);

  const onPressBook = useCallback(() => {
    if (props.book?.purchaseLink) {
      openWebpage(props.book?.purchaseLink);
    }
  }, [props.book?.purchaseLink]);

  const bookPhoto = useMemo(() => {
    if (!props.book?.photo) {
      return undefined;
    }
    return { source: props.book.photo, style: props.photoStyle, resizeMode: "contain" } as const;
  }, [props.book?.photo, props.photoStyle]);

  const publisherPhoto = useMemo(() => {
    if (!props.publisher?.photo) {
      return undefined;
    }
    return { source: props.publisher.photo, style: props.photoStyle, resizeMode: "contain" } as const;
  }, [props.publisher?.photo, props.photoStyle]);

  if (bookOrUserEnteredBook) {
    return (
      <RecipeSourceElement
        headingPrefix={props.showFromPrefix ? strings.from : undefined}
        heading={bookOrUserEnteredBook.name}
        headingFontSize={props.fontSize}
        numberOfLines={props.numberOfLines}
        textOpacity="medium"
        photo={bookPhoto}
        showPhotoPlaceholder
        onPress={props.tapEnabled && !userEnteredBook ? onPressBook : undefined}
      />
    );
  }

  if (props.sourceType === "userPhoto") {
    return (
      <RecipeSourceElement
        icon="camera"
        heading={strings.importedFromPhotos}
        headingFontSize={props.fontSize}
        numberOfLines={1}
        textOpacity="medium"
      />
    );
  }

  if (props.publisher) {
    switch (props.publisher.type) {
      case "knownPublisher": {
        return (
          <RecipeSourceElement
            headingPrefix={props.showFromPrefix ? strings.from : undefined}
            heading={props.publisher.name}
            headingFontSize={props.fontSize}
            numberOfLines={props.numberOfLines}
            textOpacity="medium"
            photo={publisherPhoto}
            showPhotoPlaceholder
            onPress={props.tapEnabled ? onPressKnownPublisher : undefined}
          />
        );
      }
      case "unknownPublisher": {
        // If author name and publisher name are the same, fall back to publisher domain for name.
        const publisherName =
          props.author?.name &&
          props.publisher.url &&
          props.publisher.name.toLowerCase() === props.author.name.toLowerCase()
            ? getUrlHostDisplayName(props.publisher.url)
            : props.publisher.name;

        return (
          <RecipeSourceElement
            headingPrefix={props.showFromPrefix ? strings.from : undefined}
            heading={publisherName}
            headingFontSize={props.fontSize}
            numberOfLines={props.numberOfLines}
            textOpacity="medium"
            photo={publisherPhoto}
            showPhotoPlaceholder
            onPress={
              props.tapEnabled ? (knownPublisherId ? onPressKnownPublisher : navToUnknownEntitySheet) : undefined
            }
          />
        );
      }
      default:
        bottomThrow(props.publisher);
    }
  }

  return null;
});

interface RecipeAuthorProps {
  /**
   * The author for the recipe. Note that userEnteredAttribution is also used to render the author in the case of a user
   * entered book, but currently, we will always have a user author set in that case, so callers can safely use
   * recipe.author to conditionally render this component.
   */
  author: Author;
  userEnteredAttribution: UserEnteredAttribution | undefined;
  fontSize: "body" | "secondary" | "tertiary";
  numberOfLines: number | "unlimited";
  sourceType: RecipeSource["type"];
  book: Book | undefined;
  noPhoto?: boolean;
  onPressAction?: "navToProfile";
  tapEnabled?: boolean;
}

export const RecipeAuthor = React.memo((props: RecipeAuthorProps) => {
  // if the user has entered a simple source, or a manually entered book, we show this in the author position
  // and do so using an unKnownAuthor to keep things simple. We also make it non-tappable below.
  const userEnteredAuthor =
    props.userEnteredAttribution?.type === "book" ? props.userEnteredAttribution.author : undefined;
  const userSimple = props.userEnteredAttribution?.type === "simple" ? props.userEnteredAttribution.name : undefined;
  const userEnteredValue = userEnteredAuthor ?? userSimple;
  const userEnteredUnknownAuthor: UnknownAuthor | undefined = userEnteredValue
    ? { type: "unknownAuthor", name: userEnteredValue }
    : undefined;
  const author = userEnteredUnknownAuthor ?? props.author;

  switch (author.type) {
    case "userAuthor": {
      return (
        <DeglazeUserAuthorByLine
          author={author}
          fontSize={props.fontSize}
          numberOflines={props.numberOfLines}
          onPressAction={props.onPressAction}
          noPhoto={props.noPhoto}
          prefix={props.sourceType === "userPhoto" && !props.book ? "addedBy" : "by"}
          tapEnabled={props.tapEnabled}
        />
      );
    }
    case "knownAuthor": {
      return (
        <KnownAuthorByLine
          author={author}
          fontSize={props.fontSize}
          numberOfLines={props.numberOfLines}
          noPhoto={props.noPhoto}
          tapEnabled={props.tapEnabled}
        />
      );
    }
    case "unknownAuthor": {
      if (author.id) {
        // Back compat case for known author
        const knownAuthor = { ...author, type: "knownAuthor", id: author.id } satisfies KnownAuthor;
        return (
          <KnownAuthorByLine
            author={knownAuthor}
            fontSize={props.fontSize}
            numberOfLines={props.numberOfLines}
            noPhoto={props.noPhoto}
            tapEnabled={props.tapEnabled}
          />
        );
      }
      return (
        <UnknownAuthorByLine
          author={author}
          fontSize={props.fontSize}
          numberOfLines={props.numberOfLines}
          noPhoto={props.noPhoto}
          tapEnabled={props.tapEnabled && !userEnteredValue}
        />
      );
    }
    default:
      bottomThrow(author);
  }
});

const DeglazeUserAuthorByLine = React.memo(
  (props: {
    author: DeglazeUser;
    fontSize: "body" | "secondary" | "tertiary";
    numberOflines: number | "unlimited";
    onPressAction?: "navToProfile";
    noPhoto?: boolean;
    prefix?: keyof typeof strings.authorPrefix;
    tapEnabled?: boolean;
  }) => {
    const navToProfile = useNavToProfileScreen(props.author.userId, "recipeTitleAndSource");
    const onPress = props.onPressAction === "navToProfile" ? navToProfile : undefined;

    return (
      <AuthorByLine
        name={props.author.name}
        fontSize={props.fontSize}
        numberOfLines={props.numberOflines}
        photo={props.noPhoto ? undefined : props.author.photo}
        onPress={props.tapEnabled ? onPress : undefined}
        prefix={props.prefix}
      />
    );
  }
);

const KnownAuthorByLine = React.memo(
  (props: {
    author: KnownAuthor;
    fontSize: "body" | "secondary" | "tertiary";
    numberOfLines: number | "unlimited";
    noPhoto?: boolean;
    tapEnabled?: boolean;
  }) => {
    const dispatch = useDispatch();
    const screen = useScreen();

    const onPress = useCallback(() => {
      dispatch(navToEntityScreen(props.author.id, screen.nav, "recipeDetail"));
    }, [dispatch, screen.nav, props.author.id]);

    return (
      <AuthorByLine
        name={props.author.name}
        fontSize={props.fontSize}
        numberOfLines={props.numberOfLines}
        photo={props.noPhoto ? undefined : props.author.photo}
        onPress={props.tapEnabled ? onPress : undefined}
      />
    );
  }
);

const UnknownAuthorByLine = React.memo(
  (props: {
    author: UnknownAuthor;
    fontSize: "body" | "secondary" | "tertiary";
    numberOfLines: number | "unlimited";
    noPhoto?: boolean;
    tapEnabled?: boolean;
  }) => {
    const navToUnknownEntitySheet = useNavToUnknownEntitySheet(props.author.name);

    return (
      <AuthorByLine
        name={props.author.name}
        fontSize={props.fontSize}
        numberOfLines={props.numberOfLines}
        photo={props.noPhoto ? undefined : props.author.photo}
        onPress={props.tapEnabled ? navToUnknownEntitySheet : undefined}
      />
    );
  }
);

const AuthorByLine = React.memo(
  (props: {
    name: string;
    fontSize: "body" | "secondary" | "tertiary";
    numberOfLines: number | "unlimited";
    photo?: PhotoRef;
    onPress?: () => void;
    prefix?: keyof typeof strings.authorPrefix;
  }) => {
    return (
      <RecipeSourceElement
        headingPrefix={strings.authorPrefix[props.prefix ?? "by"]}
        heading={props.name}
        headingFontSize={props.fontSize}
        numberOfLines={props.numberOfLines}
        textOpacity="medium"
        photo={props.photo ? { source: props.photo, style: "circle", resizeMode: "cover" } : undefined}
        onPress={props.onPress}
      />
    );
  }
);

const RecipeSourceElement = React.memo(
  (props: {
    photo?: { source: PhotoRef; style: "square" | "circle"; resizeMode: "cover" | "contain" };
    icon?: "camera";
    showPhotoPlaceholder?: boolean;
    headingPrefix?: string;
    heading: string;
    headingFontSize: "body" | "secondary" | "tertiary";
    textOpacity: keyof typeof Opacity;
    numberOfLines: number | "unlimited";
    onPress?: () => void;
  }) => {
    const photoStyle: PhotoSizeName = switchReturn(props.photo?.style ?? "square", {
      square: () => {
        return switchReturn(props.headingFontSize, {
          body: "thumbnailXsmall",
          secondary: "thumbnailXsmall",
          tertiary: "thumbnailXXsmall",
        });
      },
      circle: () => {
        return switchReturn(props.headingFontSize, {
          body: "avatarXsmall",
          secondary: "avatarXsmall",
          tertiary: "avatarXXsmall",
        });
      },
    });

    const iconSize = switchReturn(props.headingFontSize, {
      body: 24,
      secondary: 24,
      tertiary: 20,
    });

    const icon = props.icon
      ? switchReturn(props.icon, {
          camera: <IconCamera opacity="dark" size={iconSize} />,
        })
      : null;

    const onPress = useCallback(() => {
      props.onPress?.();
    }, [props.onPress]);

    return (
      <Pressable
        disabled={!props.onPress}
        onPress={onPress}
        noFeedback
        singlePress
        style={{ flexDirection: "row", alignItems: "center" }}
      >
        {!!props.photo && (
          <>
            <View>
              <Photo
                source={props.photo.source}
                style={photoStyle}
                resizeMode={props.photo.resizeMode}
                sourceSize="w288"
              />
            </View>
          </>
        )}
        {!props.photo && (
          <>
            {!!props.icon && <>{icon}</>}
            {!props.icon && !!props.showPhotoPlaceholder && (
              <>
                <Photo style={photoStyle} sourceSize="w288" />
              </>
            )}
          </>
        )}
        {!!props.heading && (
          <>
            {props.headingFontSize === "body" && (
              <>
                {!!(props.photo || props.showPhotoPlaceholder || props.icon) && <Spacer horizontal={1} />}
                <TBody
                  opacity={props.textOpacity}
                  numberOfLines={props.numberOfLines === "unlimited" ? undefined : props.numberOfLines}
                >
                  {!!props.headingPrefix && (
                    <>
                      <TBody>{props.headingPrefix}</TBody>
                      <TBody> </TBody>
                    </>
                  )}
                  <TBody fontWeight={props.onPress ? "medium" : undefined} underline={!!props.onPress}>
                    {props.heading}
                  </TBody>
                </TBody>
              </>
            )}
            {props.headingFontSize === "secondary" && (
              <>
                {!!(props.photo || props.showPhotoPlaceholder || props.icon) && <Spacer horizontal={0.7} />}
                <TSecondary
                  opacity={props.textOpacity}
                  numberOfLines={props.numberOfLines === "unlimited" ? undefined : props.numberOfLines}
                >
                  {!!props.headingPrefix && (
                    <>
                      <TSecondary>{props.headingPrefix}</TSecondary>
                      <TSecondary> </TSecondary>
                    </>
                  )}
                  <TSecondary fontWeight={props.onPress ? "medium" : undefined} underline={!!props.onPress}>
                    {props.heading}
                  </TSecondary>
                </TSecondary>
              </>
            )}
            {props.headingFontSize === "tertiary" && (
              <>
                {!!(props.photo || props.showPhotoPlaceholder || props.icon) && <Spacer horizontal={0.5} />}
                <TTertiary
                  opacity={props.textOpacity}
                  numberOfLines={props.numberOfLines === "unlimited" ? undefined : props.numberOfLines}
                >
                  {!!props.headingPrefix && (
                    <>
                      <TTertiary>{props.headingPrefix}</TTertiary>
                      <TTertiary> </TTertiary>
                    </>
                  )}
                  <TTertiary fontWeight={props.onPress ? "medium" : undefined} underline={!!props.onPress}>
                    {props.heading}
                  </TTertiary>
                </TTertiary>
              </>
            )}
          </>
        )}
      </Pressable>
    );
  }
);

export const RecipeDetailTitleAndSource = React.memo(
  (props: {
    title: string;
    sourceType: RecipeSource["type"];
    isSocialMediaRecipe: boolean;
    publisher: Publisher | undefined;
    book: Book | undefined;
    author: Author | undefined;
    userEnteredAttribution: UserEnteredAttribution | undefined;
    /**
     * Set to true if someone is viewing another user (or household's) user photo recipe
     * that has a resolved book. In other words, this will be false if a user is viewing a
     * recipe in their library.
     */
    isRestrictedBookPhotoRecipe?: boolean;
  }) => {
    const screen = useScreen();
    const userAttributedBook =
      props.userEnteredAttribution && props.userEnteredAttribution.type === "book"
        ? props.userEnteredAttribution
        : undefined;
    const publisherOrBook = props.book ?? userAttributedBook ?? props.publisher;
    const onPressAuthor = props.author?.type === "userAuthor" ? "navToProfile" : undefined;

    const onPressAddFromPhotos = useCallback(() => {
      screen.nav.switchTab("recipesTab", navTree.get.screens.recipeAddFromPhotos);
    }, [screen.nav.switchTab]);

    return (
      <>
        <Spacer vertical={1.5} />
        <RecipeTitle title={props.title} fontSize="h1" numberOfLines="unlimited" />
        {props.sourceType === "userPhoto" && !props.isRestrictedBookPhotoRecipe && (
          <>
            <Spacer vertical={1} />
            <RecipeDetailInfoMessage type="warning" messageText={strings.aiWarning} />
          </>
        )}
        {!!props.author && (
          <>
            <Spacer vertical={1} />
            <RecipeAuthor
              author={props.author}
              userEnteredAttribution={props.userEnteredAttribution}
              fontSize="secondary"
              numberOfLines="unlimited"
              onPressAction={onPressAuthor}
              tapEnabled={Platform.OS !== "web"}
              sourceType={props.sourceType}
              book={props.book}
            />
          </>
        )}
        {(!!publisherOrBook || props.sourceType === "userPhoto") && (
          <>
            <Spacer vertical={1} />
            <RecipePublisherOrBook
              author={props.author}
              book={props.book}
              userEnteredAttribution={props.userEnteredAttribution}
              publisher={props.publisher}
              sourceType={props.sourceType}
              showFromPrefix
              fontSize="secondary"
              numberOfLines="unlimited"
              photoStyle="square"
              tapEnabled={!props.isSocialMediaRecipe}
            />
          </>
        )}
        {!!props.isRestrictedBookPhotoRecipe && (
          <>
            <Spacer vertical={1.5} />
            <RecipeDetailInfoMessage
              type="info"
              messageText={
                <TSecondary opacity="dark">
                  <TSecondary>{strings.restrictedRecipe[0]}</TSecondary>
                  {Platform.OS === "web" && <TSecondary>{strings.restrictedRecipe[1]}</TSecondary>}
                  {Platform.OS !== "web" && (
                    <TSecondary
                      opacity="opaque"
                      fontWeight="medium"
                      color={globalStyleColors.colorTextLink}
                      onPress={onPressAddFromPhotos}
                      suppressHighlighting
                    >
                      {strings.restrictedRecipe[1]}
                    </TSecondary>
                  )}
                </TSecondary>
              }
            />
          </>
        )}
      </>
    );
  }
);

const RecipeDetailInfoMessage = React.memo(
  (props: { type: "warning" | "info"; messageText: string | React.ReactElement }) => {
    const iconSize = 20;

    const icon = switchReturn(props.type, {
      warning: <IconAlert opacity="dark" size={"100%"} />,
      info: <IconInfo opacity="dark" size={"100%"} />,
    });

    const message =
      typeof props.messageText === "string" ? (
        <TSecondary opacity="dark">{props.messageText}</TSecondary>
      ) : (
        props.messageText
      );

    return (
      <View style={styles.infoBox}>
        <View style={{ width: iconSize, height: iconSize }}>{icon}</View>
        <Spacer horizontal={0.5} />
        <View style={{ flexShrink: 1 }}>{message}</View>
      </View>
    );
  }
);

function useNavToUnknownEntitySheet(entityName?: string): () => void {
  const screen = useScreen();

  const navToSheet = useCallback(() => {
    if (entityName) {
      screen.nav.modal(navTree.get.screens.bottomSheet, {
        height: 250,
        content: <UknownEntitySheet name={entityName} />,
      });
    }
  }, [screen.nav.modal, entityName]);

  return navToSheet;
}

const UknownEntitySheet = React.memo((props: { name: string }) => {
  const dispatch = useDispatch();
  const bottomSheet = useBottomSheet();
  const toast = useToast();

  const [waiting, setWaiting] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      await dispatch(reportIssue({ type: "requestedEntityPage", name: props.name }, setWaiting));
      bottomSheet?.closeSheetAndGoBack();
      Haptics.feedback("operationSucceeded");
      toast.show({ type: "infoToast", message: strings.unknownEntitySheet.toast });
    } catch (err) {
      displayUnexpectedErrorAndLog("Error calling reportIssue() from UnknownEntitySheet", { entityName: props.name });
      setWaiting(false);
    }
  }, [props.name, setWaiting, bottomSheet, toast]);

  return (
    <View style={{ padding: globalStyleConstants.defaultPadding }}>
      <THeading2 numberOfLines={2} align="center">
        {strings.unknownEntitySheet.headline(props.name)}
      </THeading2>
      <Spacer vertical={1} />
      <TBody numberOfLines={2} align="center" opacity="dark">
        {strings.unknownEntitySheet.subhead(props.name)}
      </TBody>
      <Spacer vertical={3} />
      <ButtonRectangle
        title={strings.unknownEntitySheet.cta(props.name)}
        type="submit"
        onPress={onSubmit}
        waiting={waiting}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  infoBox: {
    flexDirection: "row",
    paddingHorizontal: globalStyleConstants.unitSize,
    paddingVertical: 0.5 * globalStyleConstants.unitSize,
    borderRadius: globalStyleConstants.unitSize,
    backgroundColor: globalStyleColors.colorGrey,
  },
});
