import { ErrorData, isStructuredError, StructuredError } from "@eatbetter/common-shared";

export type RecipeConflictError = ErrorData<"recipes/recipeConflict">;
export type RecipeNotFoundError = ErrorData<"recipes/recipeNotFound">;
export type RecipeCouldNotBeParsedError = ErrorData<"recipes/recipeCouldNotBeParsed">;
export type RecipeNoCanonicalUrlError = ErrorData<"recipes/recipeNoCanonicalUrlError", { hasRootPath?: boolean }>;
export type RecipeInvalidUrlError = ErrorData<"recipes/invalidUrlError">;
export type RecipeUnsupportedUrlError = ErrorData<"recipes/unsupportedUrlError">;
export type RecipeImportQuotaExceededError = ErrorData<
  "recipes/importQuotaExceeded",
  {
    /**
     * This should be the same as what's on the overall structured error userMessage optional property,
     * but including it here to ensure it's set.
     */
    userMessage: string;
  }
>;

export type RecipeErrorTypes =
  | RecipeConflictError
  | RecipeCouldNotBeParsedError
  | RecipeNotFoundError
  | RecipeNoCanonicalUrlError
  | RecipeInvalidUrlError
  | RecipeUnsupportedUrlError
  | RecipeImportQuotaExceededError;

export function isStructuredRecipeError(err: any): err is StructuredError<RecipeErrorTypes> {
  return isStructuredError(err) && err.data.code.startsWith("recipes/");
}
