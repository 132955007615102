import { useCallback } from "react";
import { Alert } from "../Alert/Alert";
import { Platform } from "react-native";

export function useRecipeEditRemoveItemConfirmation(args: {
  type: "ingredient" | "instruction" | "section";
  isEmpty: boolean;
  remove: () => void;
}) {
  const onRemove = useCallback(() => {
    if (args.isEmpty || Platform.OS === "web") {
      args.remove();
    } else {
      Alert.alert(`Delete ${args.type}`, `Are you sure you want to delete this ${args.type}?`, [
        {
          type: "delete",
          onPress: args.remove,
        },
        {
          type: "cancel",
          onPress: () => {},
        },
      ]);
    }
  }, [args.remove, args.isEmpty, args.type]);

  return onRemove;
}
