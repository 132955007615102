import { EpochMs, TypedPrimitive, UrlString } from "@eatbetter/common-shared";
import { Author, UserRecipeId } from "./RecipeTypes";
import { EntityDisplay, PhotoRef } from "@eatbetter/photos-shared";

export function adminBookToBook(adminBook: AdminBook): Book {
  return {
    id: adminBook.id,
    name: adminBook.title,
    photo: adminBook.coverPhoto,
    purchaseLink: adminBook.purchaseLink,
  };
}

/**
 * Removes dashes from an isbn and validates it's in the format we expect
 */
export function cleanAndValidateIsbn(isbn: string): Isbn {
  const cleaned = isbn.replaceAll("-", "");
  validateIsbn(cleaned);
  return cleaned;
}

/**
 * Validates an isbn is in the format we expect - 10 or 13 digits
 * with no dashes. The last character of an isbn10 identifier can be an x
 */
export function validateIsbn(isbn: string | Isbn): asserts isbn is Isbn {
  const valid13 = /^[0-9]{13}$/.test(isbn);
  const valid10 = /^[0-9]{9}[0-9x]{1}$/i.test(isbn);
  if (!valid10 && !valid13) {
    throw new Error(`Invalid isbn: ${isbn}`);
  }
}

export type BookId = TypedPrimitive<string, "BookId">;

export interface BookExternalIdentifiers {
  isbn?: Isbn;
  isbn10?: Isbn;
  googleBooksId?: GoogleBooksId;
}

export interface BookIdentifiers extends BookExternalIdentifiers {
  bookId?: BookId;
}

export type Isbn = TypedPrimitive<string, "isbn">;
export type GoogleBooksId = TypedPrimitive<string, "googleBooksId">;

export interface Book extends EntityDisplay {
  id: BookId;
  purchaseLink?: UrlString;
}

export interface AdminBook {
  id: BookId;
  externalIds: BookExternalIdentifiers;
  mappedEditions: Array<BookIdentifiers>;
  /**
   * Set if this book has been mapped to a different book. Archived will be true
   * as well if this is set.
   */
  mappedTo?: BookId;
  purchaseLink?: UrlString;
  primaryAuthor?: Author;
  otherAuthors?: Author[];
  title: string;
  coverPhoto?: PhotoRef;
  created: EpochMs;
  version: EpochMs;
  archived?: boolean;
}

export interface BookCoverInfo {
  bookAndAuthor?: {
    book: Book;
    author?: string;
  };
  titleAndAuthor?: {
    title: string;
    author?: string;
  };
  photo: PhotoRef;
}

export interface GetBookCoverInfoArgs {
  recipeId: UserRecipeId;
  photo: PhotoRef;
}
